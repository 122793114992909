import grains from "../../Imgs/community.jpg";
import premium from "../../Imgs/premium.jpg";
import { subType } from "../../reducers/user";
import { useSelector } from "react-redux";

const Community = () => {
  const accType = useSelector(subType);

  return (
    <div className="px-5 mt-5">
      <div className="  relative h-[250px] font-sand">
        <img src={""} className="h-[250px] w-full object-cover" alt="" />
        <div className="bg-[#140e1e] opacity-90 absolute h-[250px] top-0 w-full px-2">
          <h3 className="text-center mt-10 font-bold lg:text-[20px] sm:text-[15px] text-white">
            Connect with other Women who are thriving in Motherhood and Career
            while utilizing helpful resources, information, and advice in the
            Preggify Community.
          </h3>
        </div>
      </div>

      <div className="grid sm:gap-[15px] lg:gap-[30px] lg:grid-cols-4 sm:grid-cols-2 mt-10">
        <div>
          <div class="max-w-sm rounded overflow-hidden shadow-lg">
            <img class="w-full" src={grains} alt="Sunset in the mountains" />
            <div class="lg:px-6 sm:px-2 py-4">
              <div class="font-medium text-lg font-sand mb-2 text-preggifyPurple">
                Preggify Community (Free)
              </div>
              <p class="text-gray-700 text-sm font-normal font-sand">
                Click here to join our free community of mothers and mothers to
                be
              </p>
            </div>
            <div class="px-6 pt-2 pb-2">
              <a
                href="https://tinyurl.com/Preggifywomen"
                target="_blank"
                //     state={{ nut: "carbs" }}
                className="inline-block  bg-preggifyGreen rounded-full px-3 py-1 text-sm font-semibold text-white mr-2 mb-2 cursor-pointer"
              >
                Join now
              </a>
            </div>
          </div>
        </div>
        {/* start of 2nd Grid */}
        <div>
          <div class="max-w-sm rounded overflow-hidden shadow-lg">
            <img class="w-full" src={premium} alt="Sunset in the mountains" />
            <div class="lg:px-6 sm:px-2 py-4">
              <div class="font-medium text-lg font-sand mb-2 text-preggifyPurple">
                Premium community
              </div>
              <p class="text-gray-700 text-sm font-normal font-sand">
                Click here to join our premium community
              </p>
            </div>
            <div class="px-6 pt-2 pb-2">
              {accType == "premium" ? (
                <a
                  href="https://chat.whatsapp.com/H2MUQDDA0YPEQ1rKEJaJHu"
                  target="_blank"
                  //     state={{ nut: "carbs" }}
                  className="inline-block  bg-preggifyGreen rounded-full px-3 py-1 text-sm font-semibold text-white mr-2 mb-2 cursor-pointer"
                >
                  Join now
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Community;
