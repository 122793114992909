import cycle from "../../Imgs/cycle.png";
import fetus from "../../Imgs/fetus.png";
import baby from "../../Imgs/babyday.png";
import sound from "../../Imgs/ultrasound.png";
import tri1 from "../../Imgs/1st trimester.png";
import tri2 from "../../Imgs/2nd trimester.png";
import tri3 from "../../Imgs/3rd.png";
import pro from "../../Imgs/pro.png";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  calculateExpectedDeliveryDate,
  calculateGestationalAge,
} from "../../functions";
import {
  fetchProfile,
  setID,
  profile_stat,
  lastCycle,
  fname,
  subType,
  setGA,
  GANOW,
  fetchTips,
  trimesterVal,
  challanges,
  contents,
  fetchProTips,
  protip,
  procont,
  fetchAffirmations,
  testResult,
  test,
  payment_reload,
  getpregnancyvideos,
  check_sub,
  paymentRecords,
  setFree,
  subscriptionDetails,
} from "../../reducers/user";
// import { planType } from "../../reducers/registration";
// import { lastPeriod, firstName } from "../../reducers/user";
// import {
//   CartesianGrid,
//   Line,
//   LineChart,
//   ReferenceLine,
//   ResponsiveContainer,
//   XAxis,
//   YAxis,
// } from "recharts";
import { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  p,
} from "recharts";
import moment from "moment";
import { MdOutlineExpandCircleDown } from "react-icons/md";

// console.log("oeiwoieowie", LMP);

const UserHomeMunu = () => {
  const getToks = sessionStorage?.getItem("tokken");
  const sort = jwt_decode(getToks);
  const id = sort["id"];
  const dispatch = useDispatch();
  const reload = useSelector(payment_reload);
  const lastp = useSelector(lastCycle);
  const name = useSelector(fname);
  const depressionRsult = useSelector(test);
  const subtype_ = useSelector(subType);
  const ga = useSelector(GANOW);
  const presentTrimester = useSelector(trimesterVal);
  const challange = useSelector(challanges);
  const content = useSelector(contents);
  const proc = useSelector(procont);
  const ptips = useSelector(protip);
  const paymentLogs_ = useSelector(paymentRecords);
  const subtype__ = useSelector(subType);
  const [showRandomFreeTips, setRandomFreeTips] = useState();
  const [showProContent, setShowProContent] = useState();
  const [somethingChange, setSomethingChange] = useState("No");
  const [paymentLogs, setpaymentLog] = useState(paymentLogs_);

  const get_changes = useLocation();

  // useEffect(() => {
  //   dispatch(subscriptionDetails());
  //   // dispatch(subscriptionDetails());
  //   var today = moment();
  //   const last_rec = paymentLogs[paymentLogs.length - 1];
  //   const next_renew = moment(last_rec?.next_due_payment);
  //   const diff_ = next_renew.diff(today, "days");
  //   console.log("difference", diff_);
  //   if (paymentLogs.length !== 0 && diff_ == 0) {
  //     dispatch(setFree());
  //     dispatch(check_sub({ date: next_renew }));
  //   }
  // }, []);

  useEffect(() => {
    // console.log(get_changes.state);
    setSomethingChange(get_changes.state);
    dispatch(getpregnancyvideos());
  }, [somethingChange]);

  const depression_data = [];
  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  if (depressionRsult?.length > 0) {
    // const testValue = depressionRsult.map((item) => {});
    for (var i = 0; i < depressionRsult.length; i++) {
      var obj = depressionRsult[i];
      var sum = 0;
      // Iterating over the properties of each object and calculating the sum
      for (var key in obj) {
        if (
          obj.hasOwnProperty(key) &&
          key !== "date_of_test" &&
          key !== "next_date" &&
          key !== "_id"
        ) {
          sum += obj[key];
        }
      }
      // Extracting the month name from the date property
      var date = new Date(obj.date_of_test);
      var monthName = monthNames[date.getMonth()];

      // Creating a new object with the sum, highest, and month name
      var newObj = { DS: sum, DI: 20, name: monthName };

      // Adding the new object to the new array
      depression_data.push(newObj);
    }
    // console.log("wawu", depression_data);
  }

  const data = depression_data;
  const lmp = lastp;
  const formatedDate = new Date(lmp);
  const now = formatedDate.toDateString();

  const lmpDate = new Date(lmp);
  const myGA = calculateGestationalAge(lmpDate);

  //fetch changes 1 secs after LMP changes
  useEffect(() => {
    // dispatch(subscriptionDetails());
    const delay = 1000;
    // dispatch(check_sub());
    const timerId = setTimeout(() => {
      // dispatch(setID());
      dispatch(fetchProfile());
      dispatch(fetchAffirmations());
      dispatch(testResult(id));
    }, delay);
    return () => {
      clearTimeout(timerId); // Cleanup the timer when the component unmounts or the dependency changes.
    };
  }, [somethingChange, reload]);

  useEffect(() => {
    if (ga > 0 && ga < 13) {
      dispatch(fetchTips({ trimester: "first trimester" }));
      dispatch(fetchProTips({ trimester: "first trimester" }));
    } else if (ga >= 13 && ga < 29) {
      dispatch(fetchTips({ trimester: "second trimester" }));
      dispatch(fetchProTips({ trimester: "second trimester" }));
    } else if (ga > 29) {
      // console.log("yea");
      dispatch(fetchTips({ trimester: "third trimester" }));
      dispatch(fetchProTips({ trimester: "third trimester" }));
    }
  }, [ga]);

  //subtype == "premium"
  // useEffect(() => {
  //   if (ga >= 0 && ga < 13) {
  //     dispatch(fetchProTips({ trimester: "first trimester" }));
  //   } else if (ga >= 13 && ga < 29) {
  //     dispatch(fetchProTips({ trimester: "second trimester" }));
  //   } else if (ga > 29) {
  //     // console.log("yea");
  //     dispatch(fetchProTips({ trimester: "third trimester" }));
  //   }
  // }, [ga]);

  // useEffect(() => {

  // }, []);
  useEffect(() => {
    dispatch(setGA(myGA));
  }, [lmp]);

  useEffect(() => {
    const randomContent = () => {
      var keys = Object.keys(content);
      var randomIndex = Math.floor(Math.random() * keys.length);
      var randomKey = keys[randomIndex];
      var randomValue = content[randomKey];
      setRandomFreeTips(randomValue);
    };
    randomContent();
    const interval = setInterval(randomContent, 1 * 60 * 1000);
    return () => {
      clearInterval(interval); // Clear the interval on component unmount
    };
  }, [content]);
  useEffect(() => {
    const randomContent = () => {
      var keys = Object.keys(ptips);
      var randomIndex = Math.floor(Math.random() * keys.length);
      var randomKey = keys[randomIndex];
      var randomValue = ptips[randomKey];
      setShowProContent(randomValue);
    };
    randomContent();
    const interval = setInterval(randomContent, 1 * 60 * 1000);
    return () => {
      clearInterval(interval); // Clear the interval on component unmount
    };
  }, [ptips]);

  const minX = Math.min(...data.map((d) => d.x));
  const minY = Math.min(...data.map((d) => d.y));

  const preggyTipsToShow = () => {
    if (ga > 0 && ga < 13) {
      return <img src={tri1} alt="" className="lg:h-[100px]" />;
    } else if (ga >= 13 && ga < 29) {
      return <img src={tri2} alt="" className="lg:h-[100px] " />;
    } else if (ga > 29) {
      return <img src={tri3} alt="" className="lg:h-[100px]" />;
    }
  };
  //
  return (
    <>
      <div className="mt-5  px-4 overflow-auto font-sand text-sm bg-[#f3f3f9]">
        <h1 className="font-sand lg:text-xl sm:text-base font-bold">
          Dashboard
        </h1>
        <div className="grid lg:grid-cols-4 gap-[30px] sm:gap-[15px] mt-[25px] pb-[15px] sm:grid-cols-2 sm:px-0 ">
          {/* First Grid */}
          <div className="lg:h-[180px] sm:h-[120px] lg:px-5 sm:px-2 pt-2 sm:w-auto rounded-[10px] shadow-[-18px_14px_64px_0px_rgba(34, 42, 82,0.1)]  bg-[#ffffff] flex justify-between ">
            <div className=" mt-4">
              <h1 className="font-sand lg:text-[20px]  font-bold ">
                Last Menstural Period (LMP)
              </h1>
              <h1 className=" lg:mt-2 sm:mt-0 text-[13px]">
                {lmp ? (
                  now
                ) : (
                  <Link to="/userhome/settings">CLICK TO SET LMP! </Link>
                )}
              </h1>
            </div>
            <div className="justify-center">
              <div className=" mt-4 bg-[#7b8dfd] rounded-full lg:h-[55px] lg:w-[55px] sm:h-[45px] sm:w-[45px] flex justify-center items-center">
                <img className="lg:w-[40px] sm:w-[30px]" src={cycle} alt="" />
              </div>
            </div>
          </div>
          {/* End of first grid */}
          {/* Start of 2nd Grid */}
          <div className="lg:h-[180px] sm:h-[120px] lg:px-5 sm:px-2 pt-2 sm:w-auto rounded-[10px] shadow-[-18px_14px_64px_0px_rgba(34, 42, 82,0.1)] bg-[#f5fffe] flex justify-between">
            <div className=" mt-4">
              <h1 className="font-sand lg:text-[20px]  font-bold ">
                Gestational Age (GA)
              </h1>
              <h1 className="lg:mt-2 sm:mt-0 text-[13px]">
                {lmp ? calculateGestationalAge(lmpDate) : "0"} Weeks
              </h1>
            </div>
            <div className="justify-center">
              <div className=" mt-4 bg-[#5dcdc6] rounded-full lg:h-[55px] lg:w-[55px] sm:h-[45px] sm:w-[45px] flex justify-center items-center">
                <img className="lg:w-[40px] sm:w-[30px]" src={fetus} alt="" />
              </div>
            </div>
          </div>
          {/* End of 2nd grid */}
          {/* Start of 3rd */}
          <div className="lg:h-[180px] sm:h-[120px] lg:px-5 sm:px-2 pt-2 sm:w-auto rounded-[10px] shadow-[-18px_14px_64px_0px_rgba(34, 42, 82,0.1)] bg-[#fff7f0] flex justify-between">
            <div className="mt-4">
              <h1 className="font-sand lg:text-[20px]  font-bold ">
                Next Doctor's Appointment
              </h1>
              <h1 className="lg:mt-2 sm:mt-0 text-[13px]">None</h1>
            </div>
            <div className="justify-center">
              <div className=" mt-4 bg-[#fbaf68] rounded-full lg:h-[55px] lg:w-[55px] sm:h-[45px] sm:w-[45px] flex justify-center items-center">
                <img className="lg:w-[35px] sm:w-[25px]" src={sound} alt="" />
              </div>
            </div>
          </div>

          {/* End of 3rd grid */}
          <div className="lg:h-[180px] sm:h-[120px] lg:px-5 sm:px-2 pt-2 sm:w-auto rounded-[10px] shadow-[-18px_14px_64px_0px_rgba(34, 42, 82, 0.1)] bg-[#ffeded] flex justify-between">
            <div className="mt-4">
              <h1 className="font-sand lg:text-[20px]  font-bold ">
                Estimated Delivery Date (EDD)
              </h1>
              <h1 className="lg:mt-2 sm:mt-0 text-[13px]">
                {lmp ? calculateExpectedDeliveryDate(lmpDate) : "NOT SET"}
              </h1>
            </div>
            <div className="justify-center">
              <div className=" mt-4 bg-[#ffb2d3] rounded-full lg:h-[55px] lg:w-[55px] sm:h-[45px] sm:w-[45px] flex justify-center items-center">
                <img className="lg:w-[40px] sm:w-[30px]" src={baby} alt="" />
              </div>
            </div>
          </div>
          {/* End of grid 4 */}
        </div>
        {/* End of Girid */}
        <div className="grid lg:grid-cols-2   my-10 gap-[30px] font-sand sm:px-0 ">
          <div className="lg:inline bg-white shadow-[-18px_14px_35px_0_rgba(58, 58, 58, 0.1)] rounded-[10px]">
            <h1 className="text-[15px] text-black font-medium p-4">
              {depressionRsult.length > 0 ? (
                "Mental health test result"
              ) : (
                <NavLink
                  to="/userHome/MoodDetails"
                  className="  p-1 rounded-md bg-[#ff2f2f44]"
                >
                  Click to take mood test
                </NavLink>
              )}
            </h1>

            <ResponsiveContainer
              minWidth="400"
              height={444}
              className="bg-white rounded-[10px]"
            >
              <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 20,
                  right: 30,
                  left: 10,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="DS" stackId="a" fill="#790b63" />
                {/* <Bar dataKey="DI" stackId="a" fill="#82ca9d" /> */}
              </BarChart>
            </ResponsiveContainer>
          </div>
          {/* End of 2nd basis */}
          {/* start of 2nd basis */}
          <div className="">
            <div className="bg-white h-[200px] sm:h-[200px] w-full mb-4 flex flex-col  rounded-lg hover:shadow-[0_35px_60px_-15px_rgba(29, 33, 66, 0.1)] transition-all duration-300">
              <hr className=" mt-5 w-16 mx-4 border-b-4 border-[#fe2b7f] rounded-full" />
              <div className="flex justify-between">
                <div className="basis-[30%] font-sand text-sm mt-5 pl-4 flex items-center">
                  {preggyTipsToShow()}
                </div>
                <div className="basis-[70%] font-sand mt-5 ml-5 px-2">
                  {presentTrimester !== "" ? (
                    <h1 className="font-bold lg:text-xl sm:text-base ">
                      Pregnancy tips for {presentTrimester}
                    </h1>
                  ) : (
                    <Link to="/userHome/settings">
                      Click here Set LMP NOW! to start getting tips
                    </Link>
                  )}
                  {presentTrimester !== "" ? (
                    <p className=" font-medium text-[13px] mt-2 text-preggifyPurple">
                      common challange: {challange}
                    </p>
                  ) : (
                    ""
                  )}

                  <p className="mt-1 text-sm transition-all duration-300 ease-out">
                    Tips: {showRandomFreeTips}
                  </p>
                </div>
              </div>
            </div>
            {/* End of basis */}
            <div className="bg-white  h-[200px] sm:h-[] w-full mb-4 flex flex-col rounded-lg hover:shadow-[0_35px_60px_-15px_rgba(29, 33, 66, 0.1)] transition-all duration-300 ">
              <hr className=" mt-5 w-16 mx-4 border-b-4 border-[#2b71fe] rounded-full" />
              <div className=" flex justify-between">
                <div className="basis-[30%] font-sand text-sm mt-5 pl-4 flex items-center">
                  <img src={pro} alt="" className="lg:h-[100px]" />
                </div>
                {/* {subtype == "premium" ? ( */}
                <div className="basis-[70%] font-sand  mt-5 ml-5 px-2">
                  <h1 className="font-bold text-sm">Take: {proc.a}</h1>
                  <p className="font-normal text-sm mt-2 transition-all duration-300 ease-out">
                    Tips: {showProContent}
                  </p>
                </div>
              </div>

              {/* ) : (
                <p className="font-normal text-sm mt-2">
                  Subscribe to premium to get this tips
                </p>
              )} */}
            </div>
          </div>
        </div>
      </div>
      {/* End of section one */}
    </>
  );
};

export default UserHomeMunu;
