import videbg from "../../Imgs/videbg.jpg";
import pgbg from "../../Imgs/preggyy.jpg";
import man from "../../Imgs/icons/dadtobe.png";
import help from "../../Imgs/icons/helper.png";
import preggy from "../../Imgs/icons/mumtobe.png";
import myvid from "../../Imgs/icons/vcart.png";
import { useSelector } from "react-redux";
import { getpregnancyvideos, subType } from "../../reducers/user";
import { useEffect, useState } from "react";
import Pregnancy from "./Academy/pregnancyAcademy";
import PartnerAcademy from "./Academy/PartnerAcademy";
import HelpAcademy from "./Academy/helpAcademy";
import MyVideos from "./Academy/myVideos";
import { useDispatch } from "react-redux";

const Videos = () => {
  const dispatch = useDispatch();
  const accType = useSelector(subType);
  const [accademyType, setaccademyType] = useState(1);

  const showSelectedAcademy = () => {
    switch (accademyType) {
      case 1:
        return <Pregnancy />;
      case 2:
        return <PartnerAcademy />;
      case 3:
        return <HelpAcademy />;
      case 4:
        return <MyVideos />;

      default:
        break;
    }
  };
  return ( <
        >
    <
        div className="mt-5 px-5 py-7" > {
        /* <div className=" bg-preggifyPurple h-[250px] font-sand ">
                  <img src={videbg} className="h-[250px] w-full object-cover" alt="" />
                </div> */
      } <
        div className="  relative h-[250px] font-sand" >
        <
          img src={videbg}
          className="h-[250px] w-full object-cover rounded-md"
          alt="" /
        >
        <
        div className="bg-[#140e1e] bg-opacity-90 absolute h-[250px] top-0 w-full p-4 flex justify-center rounded-md items-center" >
          <
        div >
            <
        h3 className="text-center font-bold lg:text-[30px] sm:text-[15px] text-white" >
              Looking
              for reliable pregnancy information ?
              <
        /h3> <
        p className="text-center text-white" >
                Access professionally vetted pregnancy education on our academy <
        /p> <
        /div> <
        /div> <
        /div> <
        div className="flex justify-center mt-10" >
                  <
        div className=" flex justify-between gap-x-10" >
                    <
        div className={
                        accademyType == 1 ?
                          "bg-preggifyGreen bg-opacity-50 border-t-4 border-preggifyPurple px-2 py-2 rounded-md  cursor-pointer transition-all duration-300" :
                          "cursor-pointer"
                      }
                      onClick={
                        () => setaccademyType(1)} >
                      <
                        img src={preggy}
                        className="lg:h-[70px] sm:h-[55px]"
                        alt="" />
                      <
        p className="font-sand lg:text-sm text-center sm:text-[11px]" >
                        Mum - to - be <
        /p> <
        /div> <
        div className={
                            accademyType == 2 ?
                              "bg-preggifyGreen bg-opacity-50 border-t-4 border-preggifyPurple px-2 py-2 rounded-md  cursor-pointer transition-all duration-300" :
                              "cursor-pointer"
                          }
                          onClick={
                            () => setaccademyType(2)} >
                          <
                            img src={man}
                            className="lg:h-[70px] sm:h-[55px]"
                            alt="" />
                          <
        p className="font-sand lg:text-sm sm:text-[11px] text-center" >
                            Dad - to - be <
        /p> <
        /div> <
        div className={
                                accademyType == 3 ?
                                  "bg-preggifyGreen bg-opacity-50 border-t-4 border-preggifyPurple px-2 py-2 rounded-md  cursor-pointer transition-all duration-300" :
                                  "cursor-pointer"
                              }
                              onClick={
                                () => setaccademyType(3)} >
                              <
                                img src={help}
                                className="lg:h-[70px] sm:h-[55px]"
                                alt="" />
                              <
        p className="font-sand lg:text-sm sm:text-[11px] text-center" >
                                Helper <
        /p> <
        /div> {
                                  accType == "Free" ? (<
                div className={
                                      accademyType == 4 ?
                                        "bg-preggifyGreen bg-opacity-50 border-t-4 border-preggifyPurple px-2 py-2 rounded-md   cursor-pointer transition-all duration-300" :
                                        "cursor-pointer"
                                    }
                                    onClick={
                                      () => setaccademyType(4)} >
                                    <
                                      img src={myvid}
                                      className="lg:h-[60px] sm:h-[55px] mt-2"
                                      alt="" /
                                    >
                                    <
                p className="font-sand lg:text-sm sm:text-[11px] text-center" >
                                      My Videos <
                /p> <
                /div>
                                      ) : (
                                      ""
                                      )
        } <
        /div> <
        /div>

                                      { /* Grid settings */} <
        div className="grid lg:gap-[30px] sm:gap-[15px] lg:grid-cols-4 mt-10 sm:grid-cols-2" > {showSelectedAcademy()} <
        /div> <
        /div> <
        />
                                        );
};

                                        export default Videos;