import carbs from "../../Imgs/carbs.jpg";
import cereal from "../../Imgs/cereal.jpg";
import fruits from "../../Imgs/fruits.jpg";
import veg from "../../Imgs/vegitable.jpg";
import tub from "../../Imgs/tuber.jpg";
import prot from "../../Imgs/protein.jpg";
import soup from "../../Imgs/soups.jpg";
import snacks from "../../Imgs/snacks.jpg";
import grains from "../../Imgs/grains.jpg";

export const food = [{
      img: carbs,
      class: "Breakfast",
      list: [
            "Bolied Yam + Stirfry Veggies",
            "Boiled semi ripe plantain + Egg sauce",
            "Yam Porridge + Boiled egg",
            "Cornflakes +  Milk + 1 Apple",
            "Golden morn + milk + peanuts (optional)",
            "Beans cake (akara) + Pap",
            "pancakes + hot milk",
            "Moi Moi + Pap + Fish",
            "Oat meal + Milk + Berries",
            "Bread toast + Scrambled egg + Warm beverage",

      ]
},
{
      img: grains,
      class: "Grains",
      list: ["Brown Rice",
            "Lentils",
            "Quinoam",
            "Barley",
            "Oats",
            "Spelt",
            "Fonio",
            "Wheat",
            "Buckwheat",
            "Bulgar Wheat"
      ]
},
{
      img: cereal,
      class: "Lunch",
      list: [
            "Garri + Okra soup",
            "Beans porridge + Plantain + Vegitables",
            "Jollof rice + Steamed veggies + Fish",
            "Yam porridge + Diced liver",
            "Pounded yam + Egusi",
            "Amala +Ewedu",
            "Semolina + Groundnut soup",
            "Fufu + Oha soup",
            "Fufu + ogbono soup",
            "Fried rice  + Diced gizzard",
            "Fried plantain  + Egg sauce",
            "Sandwich  + Fruit juice",
            "Boiled spaghetti  + Stew + Protein of choice",
            "Noodles + Egg + Veggies",
            "Macaroni + beef stew",
      ]
},
{
      img: fruits,
      class: "Fruits",
      list: [
            "Oranges",
            "Mangoes",
            "Pineapples",
            "Guavas",
            "Papayas",
            "Tangerines",
            "Bananas",
            "Avocado",
            "Apples",
            "Grapes",
            "Pawpaw",
            "Coconut",
            "Watermelon"
      ]
},
{
      img: veg,
      class: "Dinner",
      list: [
            "Catfish pepper soup + Agidi (Eko)",
            "Chicken ,veggies + Fresh Juice",
            "Rice + Beans + Chicken",
            "Grilled chicken + Salad",
            "Grilled or fried fish + Salad + Fresh juice",
            "Bean + Corn",
            "Ukwa porridge",
            "Coconut rice + Protein of choice",
            "Spaghetti + Boiled egg",
            "Banga stew + Rice + Protein of choice",
            "Chicken, fish or goatmeat pepper soup + Agidi (Eko)",
            "Plantain porridge",
            "Pigeon pea + Yam ",
            "Beans + Spaghetti ",
            "Jollof rice + Fish",
      ]
},
{
      img: tub,
      class: "Tubers",
      list: [
            "Plantains",
            "Cassava",
            "Yam",
            "Potatoes (sweet or Irish)",
            "Cocoyams",
            "Taro",
            "Sweet Potato"
      ]

},
{
      img: prot,
      class: "Protein",
      list: [
            "Chicken",
            "Turkey",
            "Beef",
            "Goat Meat",
            "Fish",
            "Prawns",
            "Crayfish",
            "Lentils",
            "Nkwobi",
            "Suya",
            "Akara (Bean Cakes)",
            "Moi Moi",
            "Pepper Soup",
            "Roasted or Grilled Corn"
      ]
},
{
      img: soup,
      class: "Soups",
      list: [
            "Groundnut soup",
            "Egusi Soup",
            "Ogbono Soup",
            "Efo Riro",
            "Okro Soup",
            "Ewedu",
            "Bitter leaf soup",
            "Oha soup",
            "Mushroom",
            "Banga soup",
            "Afang soup",
            "Owo soup",
            "Efo Riro",
            "Gbegiri soup",
            "Ewedu soup",
            "Miyan Taushe",
            "Miyan Kuka",
            "Miyan Zogale",
            "Okazi",
            "Ugba",
            "Ofe Nsala",
            "Edikang Ikong Soup",
            "Fisherman Soup"
      ]

},
{
      img: snacks,
      class: "Snacks and beverages",
      list: ["200M Yogurt/ day",
            "Tigernut",
            "Apple",
            "Orange",
            "Carrots",
            "Banana",
            "Grapes",
            "Groundnuts",
            "Corn",
            "Pears",
            "Almond",
            "Watermelon",
      ]
}


]


// const depressionQuestion=[]