import videbg from "../../Imgs/pregnancyyoga.jpg";
import pgbg from "../../Imgs/fitness.jpg";
const Excercise = () => {
  return (
    <>
      <div className="mt-5 px-5 py-7">
        <div className=" bg-preggifyPurple h-[250px] font-sand relative">
          <img
            src={videbg}
            className="h-[250px] w-full object-cover rounded-md"
            alt=""
          />
          <div className="bg-[#140e1e] bg-opacity-90 absolute h-[250px] top-0 w-full p-4 flex justify-center rounded-md items-center">
            <div>
              <h3 className="text-center font-bold lg:text-[20px] sm:text-[15px] text-white">
                Keep fit during pregnancy with mild to moderate exercise made
                for you. Note: seek professional advice before
                engaging in exercise.
              </h3>
              <p className="text-center text-white"></p>
            </div>
          </div>
        </div>
        {/* Grid settings */}
        <div className="grid gap-[15px] lg:grid-cols-4 sm:grid-cols-2 mt-10">
          <div>
            <div class="max-w-sm rounded overflow-hidden shadow-lg">
              <img class="w-full" src={pgbg} alt="Sunset in the mountains" />
              <div class="px-6 py-4">
                <div class="font-medium text-lg font-sand mb-2 text-preggifyPurple">
                  TRIMESTER 1 EXERCISE
                </div>
                <p class="text-gray-700 text-sm font-normal font-sand">
                  These routines help alleviate discomfort and build a strong
                  foundation for the upcoming stages.
                </p>
              </div>
              <div class="px-6 pt-2 pb-2">
                <span class="inline-block  bg-preggifyGreen rounded-full px-3 py-1 text-sm font-semibold text-white mr-2 mb-2">
                  Coming Soon
                </span>
              </div>
            </div>
          </div>
          {/* start of 2nd Grid */}
          <div>
            <div class="max-w-sm rounded overflow-hidden shadow-lg">
              <img class="w-full" src={pgbg} alt="Sunset in the mountains" />
              <div class="px-6 py-4">
                <div class="font-medium text-lg font-sand mb-2 text-preggifyPurple">
                  TRIMESTER 2 EXERCISE
                </div>
                <p class="text-gray-700 text-sm font-normal font-sand">
                  These workouts contribute to increased energy levels and
                  improved posture.
                </p>
              </div>
              <div class="px-6 pt-2 pb-2">
                <span class="inline-block  bg-preggifyGreen rounded-full px-3 py-1 text-sm font-semibold text-white mr-2 mb-2">
                  Coming Soon
                </span>
              </div>
            </div>
          </div>
          {/* Grid 03 */}
          <div className="03">
            <div class="max-w-sm rounded overflow-hidden shadow-lg">
              <img class="w-full" src={pgbg} alt="Sunset in the mountains" />
              <div class="px-6 py-4">
                <div class="font-medium text-lg font-sand mb-2 text-preggifyPurple">
                  TRIMISTER 3 EXERCISE
                </div>
                <p class="text-gray-700 text-sm font-normal font-sand">
                  These activities help maintain mobility, manage discomfort,
                  and prepare the body for childbirth
                </p>
              </div>
              <div class="px-6 pt-2 pb-2">
                <span class="inline-block  bg-preggifyGreen rounded-full px-3 py-1 text-sm font-semibold text-white mr-2 mb-2">
                  Coming soon
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Excercise;
