import Datepicker from "flowbite-datepicker/Datepicker";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { lmpConfig } from "../../../reducers/user";
import { useSelector } from "react-redux";
import { myID } from "../../../reducers/user";
import { loading } from "../../../reducers/user";
import { redirect } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { current } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";

const MensturalSettings = () => {
  const [lmp, setLmp] = useState("0-0-0");
  const navigate = useNavigate();
  // const history = useHistory();

  const [first_pregnancy, setFirstPregnancy] = useState("");
  const [miscarriage, setMiscarriage] = useState("");
  const [pcdp, setPcdp] = useState({});
  const [chronicdisease, setChronicdisease] = useState("");
  const [dy, setDy] = useState("");
  const [hmbaycn, setHmbaycn] = useState("");
  const [csfs, setCsfs] = useState("");
  const [IVF, setIVF] = useState("");
  const [experience, setExperience] = useState("");
  const [diff, setDiff] = useState("");
  const [future, setFuture] = useState("");
  const dispatch = useDispatch();
  const _id = useSelector(myID);
  const _loading = useSelector(loading);
  const [done, setDone] = "No";
  const [previouspregnancy, setPreviouspregnancy] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [question, setQuestion] = useState(1);

  // console.log("LMP", lmp);
  console.log(chronicdisease);
  // console.log(pcdp);
  useEffect(() => {
    const percent = (question / 6) * 100;
    setPercentage(percent);
  }, [question]);

  console.log("difference/future", diff, future);

  useEffect(() => {
    if (lmp !== "0-0-0") {
      // console.log("ahhh");
      //get the current date...
      const now = new Date();
      const selected_lmp = new Date(lmp);
      const monthDiff =
        (now.getFullYear() - selected_lmp.getFullYear()) * 12 +
        (now.getMonth() - selected_lmp.getMonth());

      if (monthDiff === 10 || monthDiff > 10) {
        // alert("Your LMP can not be older than 9 months !");
        console.log(monthDiff);
        toast.warning("Your LMP can not be older than 9 months !");
        setDiff(monthDiff);
      } else if (selected_lmp > now) {
        setFuture(diff);
        toast.warning("LMP should not be greater than today!");
        // alert("LMP should not be greater than today!");
      } else {
        setDiff("");
        setFuture("");
      }
    }
  }, [lmp]);

  const handleDiseasesChange = (e) => {
    const { checked, name } = e.target;

    setChronicdisease((previous) => ({
      ...previous,
      [name]: checked,
    }));
  };
  const previousComplications = (e) => {
    const { checked, name } = e.target;

    setPcdp((previous) => ({
      ...previous,
      [name]: checked,
    }));
  };
  const smokeOrDrink = (e) => {
    const { checked, name } = e.target;

    setDy((previous) => ({
      ...previous,
      [name]: checked,
    }));
  };

  const submitHistory = () => {
    dispatch(
      lmpConfig({
        id: _id,
        lmp: lmp,
        first_pregnancy,
        miscarriage,
        pcdp,
        chronicdisease,
        dy,
        hmbaycn,
        csfs,
        IVF,
        experience,
        previouspregnancy,
      })
    );
    navigate("/userHome/Dashboard", { state: "yes" });
    // window.location.href = "/userHome/Dashboard";
  };
  //Return question based question state............
  const questionToSet = () => {
    switch (question) {
      case 1:
        return (
          <>
            <div className="mt-10">
              <label
                htmlFor=""
                className="block  text-sm font-sand  font-medium text-deeperPurple dark:text-white"
              >
                Enter Your Last Menstural Period(LMP)
              </label>
              <input
                onChange={(e) => setLmp(e.target.value)}
                name="LMP"
                // id="datepickerId"
                value={lmp}
                type="date"
                required
                className="bg-white border-preggifyPurple font-sand mt-4  text-gray-900 text-sm rounded-lg focus:bg-white focus:border-preggifyPurple block w-full pl-10 p-2.5 mb-2 "
              />
            </div>
            <hr className="bg-preggifyPurple h-[1px] " />
            <div className="  mt-2 mb-2 ">
              <label
                htmlFor=""
                className="block  text-sm font-sand  font-medium text-deeperPurple dark:text-white"
              >
                Is this your first pregnancy?
              </label>
              <div className="inline-block mt-2">
                <input
                  type="radio"
                  name="1"
                  value="true"
                  id="first"
                  className=""
                  checked={first_pregnancy == "true"}
                  onClick={(e) => setFirstPregnancy(e.target.value)}
                />
                <label htmlFor="first" className="px-2">
                  Yes
                </label>
                <br />
                <input
                  type="radio"
                  name="1"
                  id=""
                  value="false"
                  checked={first_pregnancy == "false"}
                  onClick={(e) => setFirstPregnancy(e.target.value)}
                />
                <label htmlFor="" className="px-2">
                  No
                </label>
                {first_pregnancy == "false" ? (
                  <div>
                    <br />
                    <label
                      htmlFor=""
                      className="block  text-sm font-sand  font-medium text-deeperPurple dark:text-white"
                    >
                      How many times have you being pregnant before?
                    </label>
                    <input
                      onChange={(e) => setPreviouspregnancy(e.target.value)}
                      type="radio"
                      name="pb"
                      id=""
                      value="1"
                    />
                    <label htmlFor="" className="px-2">
                      1
                    </label>
                    <br />
                    <input
                      onChange={(e) => setPreviouspregnancy(e.target.value)}
                      type="radio"
                      name="pb"
                      id=""
                      value="2"
                    />
                    <label htmlFor="" className="px-2">
                      2
                    </label>
                    <br />
                    <input
                      onChange={(e) => setPreviouspregnancy(e.target.value)}
                      type="radio"
                      name="pb"
                      id=""
                      value="3"
                    />
                    <label htmlFor="" className="px-2">
                      3
                    </label>
                    <br />
                    <input
                      onChange={(e) => setPreviouspregnancy(e.target.value)}
                      type="radio"
                      name="pb"
                      id=""
                      value="4"
                    />
                    <label htmlFor="" className="px-2">
                      4
                    </label>
                    <br />
                    <input
                      onChange={(e) => setPreviouspregnancy(e.target.value)}
                      type="radio"
                      name="pb"
                      id=""
                      value=">4"
                    />
                    <label htmlFor="" className="px-2">
                      {">"}4
                    </label>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* Question 3 */}
            <hr className="bg-preggifyPurple h-[1px] " />
            <div className="  mt-2 mb-2 ">
              <label
                htmlFor=""
                className="block  text-sm font-sand  font-medium text-deeperPurple dark:text-white"
              >
                Have you had a miscarriage or still birth? If yes, how many
              </label>
              <div className="inline-block mt-2">
                <input
                  type="radio"
                  name="2"
                  value="1-2"
                  id="first"
                  className=""
                  checked={miscarriage == "1-2"}
                  onClick={(e) => setMiscarriage(e.target.value)}
                />
                <label htmlFor="first" className="px-2">
                  1-2
                </label>
                <br />
                <input
                  type="radio"
                  name="2"
                  id=""
                  value="3-5"
                  checked={miscarriage == "3-5"}
                  onClick={(e) => setMiscarriage(e.target.value)}
                />
                <label htmlFor="" className="px-2">
                  3-5
                </label>
                <br />
                <input
                  type="radio"
                  name="2"
                  id=""
                  value=">5"
                  checked={miscarriage == "2"}
                  onClick={(e) => setMiscarriage(e.target.value)}
                />
                <label htmlFor="" className="px-2">
                  {` > `}5
                </label>
                <br />
                <input
                  type="radio"
                  name="2"
                  id=""
                  value="None"
                  checked={miscarriage == "None"}
                  onClick={(e) => setMiscarriage(e.target.value)}
                />
                <label htmlFor="" className="px-2">
                  None
                </label>
              </div>
            </div>
          </>
        );

        break;
      case 2:
        return (
          <>
            {/* <hr className="bg-preggifyPurple h-[1px] " /> */}
            <div className="  mt-2 mb-2 ">
              <label
                htmlFor=""
                className="block text-sm font-sand  font-medium text-deeperPurple dark:text-white"
              >
                Which of the following apply to you (click all that applies)
              </label>
              <div className="inline-block mt-2">
                <input
                  type="checkbox"
                  name="Diabetes"
                  id="first"
                  className=""
                  checked={chronicdisease?.Diabetes}
                  // checked={}
                  onChange={(e) => handleDiseasesChange(e)}
                />
                <label htmlFor="first" className="px-2">
                  Diabetes
                </label>
                <br />
                <input
                  type="checkbox"
                  id=""
                  name="Hypertension"
                  checked={chronicdisease?.Hypertension}
                  onChange={(e) => handleDiseasesChange(e)}
                />
                <label htmlFor="" className="px-2">
                  Hypertension
                </label>
                <br />
                <input
                  type="checkbox"
                  name="Thyroid_disorders"
                  id=""
                  checked={chronicdisease?.Thyroid_disorders}
                  onChange={(e) => handleDiseasesChange(e)}
                />
                <label htmlFor="" className="px-2">
                  Thyroid disorders
                </label>
                <br />
                <input
                  type="checkbox"
                  name="Asthma"
                  checked={chronicdisease?.Asthma}
                  onChange={(e) => handleDiseasesChange(e)}
                />
                <label htmlFor="" className="px-2">
                  Asthma
                </label>
                <br />
                <input
                  type="checkbox"
                  name="HIV"
                  id=""
                  value="1"
                  checked={chronicdisease?.HIV}
                  onChange={(e) => handleDiseasesChange(e)}
                />
                <label htmlFor="" className="px-2">
                  HIV
                </label>
                <br />
                <input
                  type="checkbox"
                  name="Hepatitis_B"
                  id=""
                  checked={chronicdisease?.Hepatitis_B}
                  onChange={(e) => handleDiseasesChange(e)}
                />
                <label htmlFor="" className="px-2">
                  Hepatitis B
                </label>
                <br />
                <input
                  type="checkbox"
                  name="Hepatitis_C"
                  checked={chronicdisease?.Hepatitis_C}
                  onChange={(e) => handleDiseasesChange(e)}
                />
                <label htmlFor="" className="px-2">
                  Hepatitis C
                </label>
                <br />
                <input
                  type="checkbox"
                  name="Fibroid"
                  id=""
                  checked={chronicdisease?.Fibroid}
                  onChange={(e) => handleDiseasesChange(e)}
                />
                <label htmlFor="" className="px-2">
                  Fibroid
                </label>
                <br />
                <input
                  type="checkbox"
                  name="None"
                  id=""
                  checked={chronicdisease?.None}
                  onChange={(e) => handleDiseasesChange(e)}
                />
                <label htmlFor="" className="px-2">
                  None
                </label>
              </div>
            </div>
          </>
        );
        break;
      case 3:
        return (
          <>
            <div className="  mt-2 mb-2 ">
              <label
                htmlFor=""
                className="block text-sm font-sand  font-medium text-deeperPurple dark:text-white"
              >
                Previous complications during pregnancy? (click all that
                applies)
              </label>
              <div className="inline-block mt-2">
                <input
                  type="checkbox"
                  value="0"
                  name="Pre_eclampsia"
                  className=""
                  checked={pcdp?.Pre_eclampsia}
                  onChange={(e) => previousComplications(e)}
                />
                <label htmlFor="first" className="px-2">
                  Pre-eclampsia
                </label>
                <br />
                <input
                  type="checkbox"
                  name="Gestational_diabetes"
                  checked={pcdp?.Gestational_diabetes}
                  onChange={(e) => previousComplications(e)}
                />

                <label htmlFor="" className="px-2">
                  Diabetes in Pregnancy (Sugar)
                </label>
                <br />
                <input
                  type="checkbox"
                  name="Preterm_labors"
                  id=""
                  checked={pcdp?.Preterm_labors}
                  onChange={(e) => previousComplications(e)}
                />
                <label htmlFor="" className="px-2">
                  Preterm labors
                </label>
                <br />
                <input
                  type="checkbox"
                  name="Cervical_incompetence"
                  id=""
                  checked={pcdp?.Cervical_incompetence}
                  onChange={(e) => previousComplications(e)}
                />
                <label htmlFor="" className="px-2">
                  Cervical incompetence
                </label>
                <br />
                <input
                  type="checkbox"
                  name="Placenta_previa_Abruptio"
                  id=""
                  checked={pcdp?.Placenta_previa_Abruptio}
                  onChange={(e) => previousComplications(e)}
                />
                <label htmlFor="" className="px-2">
                  Placenta previa/Abruptio
                </label>
                <br />
                <input
                  type="checkbox"
                  name="Bleeding_during_or_after_pregnancy"
                  id=""
                  checked={pcdp?.Bleeding_during_or_after_pregnancy}
                  onChange={(e) => previousComplications(e)}
                />
                <label htmlFor="" className="px-2">
                  Bleeding during or after pregnancy
                </label>
                <br />
                <input
                  type="checkbox"
                  name="None"
                  id=""
                  checked={pcdp?.None}
                  onChange={(e) => previousComplications(e)}
                />
                <label htmlFor="" className="px-2">
                  None
                </label>
              </div>
            </div>
          </>
        );
        break;
      case 4:
        return (
          <>
            <div className="  mt-2 mb-2 ">
              <label
                htmlFor=""
                className="block text-sm font-sand  font-medium text-deeperPurple dark:text-white"
              >
                Do you?
              </label>
              <div className="inline-block mt-2">
                <input
                  type="checkbox"
                  name="Smoke"
                  id="first"
                  className=""
                  checked={dy?.Smoke}
                  onChange={(e) => smokeOrDrink(e)}
                />
                <label htmlFor="first" className="px-2">
                  Smoke
                </label>
                <br />
                <input
                  type="checkbox"
                  name="Consume_alcohol"
                  checked={dy?.Consume_alcohol}
                  onChange={(e) => smokeOrDrink(e)}
                />
                <label htmlFor="" className="px-2">
                  Consume alcohol
                </label>
                <br />
                <input
                  type="checkbox"
                  name="Use_drugs"
                  id=""
                  checked={dy?.Use_drugs}
                  onChange={(e) => smokeOrDrink(e)}
                />
                <label htmlFor="" className="px-2">
                  Use drugs
                </label>
                <br />
                <input
                  type="checkbox"
                  name="None"
                  id=""
                  checked={dy?.None}
                  onChange={(e) => smokeOrDrink(e)}
                />
                <label htmlFor="" className="px-2">
                  None
                </label>
              </div>
            </div>
          </>
        );
        break;
      case 5:
        return (
          <>
            <div className="  mt-2 mb-2 ">
              <label
                htmlFor=""
                className="block  text-sm font-sand  font-medium text-deeperPurple dark:text-white"
              >
                How many babies are you carrying now?
              </label>
              <div className="inline-block mt-2">
                <input
                  type="radio"
                  name="baby"
                  value="Single"
                  id="first"
                  className=""
                  checked={hmbaycn == "Single"}
                  onClick={(e) => setHmbaycn(e.target.value)}
                />
                <label htmlFor="first" className="px-2">
                  Single
                </label>
                <br />
                <input
                  type="radio"
                  name="baby"
                  id=""
                  value="Twin_pregnancy"
                  checked={hmbaycn == "Twin_pregnancy"}
                  onClick={(e) => setHmbaycn(e.target.value)}
                />
                <label htmlFor="" className="px-2">
                  Twin pregnancy
                </label>
                <br />
                <input
                  type="radio"
                  name="baby"
                  id=""
                  value="Tripplet_or_more"
                  checked={hmbaycn == "Tripplet_or_more"}
                  onClick={(e) => setHmbaycn(e.target.value)}
                />
                <label htmlFor="" className="px-2">
                  Tripplet or more
                </label>
                <br />
                <input
                  type="radio"
                  name="baby"
                  id=""
                  value="No_idea"
                  checked={hmbaycn == "No_idea"}
                  onClick={(e) => setHmbaycn(e.target.value)}
                />
                <label htmlFor="" className="px-2">
                  No idea
                </label>
              </div>
            </div>
            <hr className="bg-preggifyPurple h-[1px] " />
            <div className="mt-2 mb-2 ">
              <label
                htmlFor=""
                className="block  text-sm font-sand  font-medium text-deeperPurple dark:text-white"
              >
                Have you had Ceserean section or fibroid surgery? If yes, how
                many times?
              </label>
              <div className="inline-block mt-2">
                <input
                  type="radio"
                  name="cs"
                  value="1_C/S"
                  id="first"
                  className=""
                  checked={csfs == "1_C/S"}
                  onClick={(e) => setCsfs(e.target.value)}
                />
                <label htmlFor="first" className="px-2">
                  1 C/S
                </label>
                <br />
                <input
                  type="radio"
                  name="cs"
                  id=""
                  value="2-3_C/S"
                  checked={csfs == "2-3_C/S"}
                  onClick={(e) => setCsfs(e.target.value)}
                />
                <label htmlFor="" className="px-2">
                  2-3 C/S
                </label>
                <br />
                <input
                  type="radio"
                  name="cs"
                  id=""
                  value="Only_fibroid_surgery"
                  checked={csfs == "Only_fibroid_surgery"}
                  onClick={(e) => setCsfs(e.target.value)}
                />
                <label htmlFor="" className="px-2">
                  Only fibroid surgery
                </label>
                <br />
                <input
                  type="radio"
                  name="cs"
                  id=""
                  value="None"
                  checked={csfs == "None"}
                  onClick={(e) => setCsfs(e.target.value)}
                />
                <label htmlFor="" className="px-2">
                  None
                </label>
              </div>
            </div>
          </>
        );
        break;
      case 6:
        return (
          <>
            <div className="  mt-2 mb-2 ">
              <label
                htmlFor=""
                className="block  text-sm font-sand  font-medium text-deeperPurple dark:text-white"
              >
                Was this pregnancy achieved through IVF or other assisted
                reproductive method?
              </label>
              <div className="inline-block mt-2">
                <input
                  type="radio"
                  name="IVF"
                  value="Yes"
                  id="first"
                  className=""
                  checked={IVF == "Yes"}
                  onClick={(e) => setIVF(e.target.value)}
                />
                <label htmlFor="first" className="px-2">
                  Yes
                </label>
                <br />
                <input
                  type="radio"
                  name="IVF"
                  id=""
                  value="No"
                  checked={IVF == "No"}
                  onClick={(e) => setIVF(e.target.value)}
                />
                <label htmlFor="" className="px-2">
                  No
                </label>
              </div>
            </div>

            <div className=" mt-10">
              <label
                htmlFor=""
                className="block  text-sm font-sand  font-medium text-deeperPurple dark:text-white"
              >
                Are you currently experiencing any health issues, such as
                infections, chronic illnesses, or autoimmune disorders? Name it
              </label>
              <input
                // onChange={(e) => setLmp(e.target.value)}
                name="LMP"
                type="text"
                value={experience}
                required
                onChange={(e) => setExperience(e.target.value)}
                className="bg-white border-deeperPurple font-sand mt-4  text-gray-900 text-sm rounded-lg focus:bg-white focus:border-preggifyPurple block w-full pl-10 p-2.5 mb-2 "
              />
            </div>
          </>
        );

      default:
        break;
    }
  };

  return (
    <>
      <div className="font-sand">
        <p className="text-sm text-deeperPurple  font-semibold">
          At Preggify, we acknowledge the unique ways in which pregnancy impacts
          women. That is why we have established this screening process,
          allowing us to offer personalized care tailored to your specific
          needs, regardless of whether you fall into the high-risk or
          low-risk category.
        </p>
        <p className="text-sm text-black mt-2">
          Kindly fill out this form with all carefulness to enable us serve you
          better.
        </p>
        {/* Question box */}
        <div className="bg-[#ffebfb] rounded-md mt-5 px-5 py-5">
          <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
            <div
              className="bg-alternateGreen h-2.5 rounded-full"
              style={{ width: percentage + "%" }}
            ></div>
          </div>
          {questionToSet()}
          <div className=" flex">
            {question > 1 ? (
              <button
                onClick={() => setQuestion(question - 1)}
                className="ml-2 text-sm text-white bg-[#790b63] h-10 w-10 flex justify-center items-center rounded-full"
              >
                <AiOutlineArrowLeft size={25} />
              </button>
            ) : (
              ""
            )}
            {question !== 6 ? (
              <button
                onClick={() => setQuestion(question + 1)}
                className="ml-2 text-sm text-white bg-[#790b63] h-10 w-10 flex justify-center items-center rounded-full"
              >
                <AiOutlineArrowRight size={25} />
              </button>
            ) : (
              ""
            )}
          </div>
        </div>

        {lmp !== "0-0-0" &&
        lmp !== undefined &&
        lmp !== "" &&
        future == "" &&
        experience !== "" &&
        pcdp !== "" &&
        IVF !== "" &&
        chronicdisease !== "" &&
        first_pregnancy !== "" &&
        csfs !== "" &&
        hmbaycn !== "" &&
        miscarriage !== "" &&
        diff == "" &&
        dy !== "" ? (
          <button
            // to="/userHome/Dashboard"
            onClick={() => submitHistory()}
            className="mt-5 bg-deeperPurple mb-2 text-white h-10 w-[120px] px-2 py-2 rounded-full"
            required
          >
            <span className="px-2 py-2 font-sand text-sm font-medium">
              submit
            </span>
            <svg
              aria-hidden="true"
              role="status"
              className={
                _loading == true
                  ? "inline w-4 h-4 mr-3 text-white animate-spin"
                  : "inline w-0 h-0 mr-3 text-white "
              }
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
              />
            </svg>
          </button>
        ) : (
          <p className="font-sand mt-5 font-semibold text-deeperPurple">
            Kindly fill in all details to submit
          </p>
        )}
      </div>
    </>
  );
};

export default MensturalSettings;
