import { useLocation } from "react-router-dom";
import { food } from "./foodList";

const FoodDets = () => {
  const location = useLocation();
  const { nut } = location.state;

  const getDet = food.find((x) => x.class === nut);
  console.log(getDet);

  return (
    <>
      <div className="px-5 font-sand">
        <img
          className="h-[250px] w-full object-cover mt-5"
          src={getDet.img}
          alt=""
        />
        <h3 className="mt-5 font-bold text-preggifyPurple">{getDet.class}</h3>
        <ul>
          {getDet.list.map((x) => {
            return <li>{x}</li>;
          })}
        </ul>
      </div>
    </>
  );
};

export default FoodDets;
