import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { PaystackButton } from "react-paystack";
import {
  firstName,
  lastName,
  email,
  phone,
  payment_reload,
  paymentRecords,
  update_subscription_status,
  subType,
  loading,
  refresher,
  fetchProfile,
  setPremium,
  subscriptionDetails,
} from "../../../reducers/user";
import { Collapse } from "react-collapse";
const kie = process.env.REACT_APP_MY_STACK_KEY;
// const kie = "pk_test_15027b7303a10e5e522516d0abfd4be7da9c7680";
const Subscription = () => {
  const [log, setpaymentLog] = useState("");
  const dispatch = useDispatch();
  const load = useSelector(loading);
  const name = useSelector(firstName);
  const acctype_ = useSelector(subType);
  const phoneNo = useSelector(phone);
  const mail = useSelector(email);
  // const refresher_ = useSelector(refresher);
  const [policy, setPolicy] = useState(false);
  const [acctype, setAcctype] = useState(acctype_);
  // const [paint, setPaint] = useState(false);

  let paymentLogs = useSelector(paymentRecords);
  var lastData = "";
  var timeDifferenceDays = "";
  var now = "";
  var targetDate;
  useEffect(() => {
    if (paymentLogs != "" && paymentLogs !== undefined) {
      lastData = paymentLogs[paymentLogs.length - 1];
      setpaymentLog(lastData);
    } else {
      setpaymentLog("");
    }
    // console.log("hello");
  }, [paymentLogs]);
  targetDate = moment(log?.next_due_payment);
  var today = moment();
  if (log?.payment_status !== "free") {
    timeDifferenceDays = targetDate.diff(today, "days");
    // console.log("shit", timeDifferenceDays);
    targetDate = moment(log?.next_due_payment);
    now = today.isSame(targetDate, "day");
  }
  // console.log("now", log?.trial_period, log?.initial_sub_amount);
  //5k box text.............................................................
  // console.log("lol", log);
  const componentProps = {
    className: "text-white font-sand font-normal",
    email: mail,
    amount: 50000 * 100,
    metadata: {
      name: name,
      phone: phoneNo,
    },
    publicKey: kie,
    text: "Subscribe",
    onSuccess: (reference) => {
      alert("Thanks for doing business with us! Come back soon!!", reference);
      dispatch(
        update_subscription_status({
          amount: 5000,
          reference: reference.reference,
          status: reference.status,
        })
      );
      dispatch(setPremium());
      // dispatch(fetchProfile());
      dispatch(subscriptionDetails());
    },

    onClose: () => alert("Wait! Don't leave :("),
  };
  //higher payment box text.............................................................
  const higerPayment = {
    className: "text-white font-sand font-normal",
    email: mail,
    amount: 50000 * 100,
    metadata: {
      name: name,
      phone: phoneNo,
    },
    publicKey: kie,
    text: "Subscribe",
    onSuccess: (reference) => {
      alert("Thanks for doing business with us! Come back soon!!", reference);
      dispatch(
        update_subscription_status({
          amount: 50000,
          reference: reference.reference,
          status: reference.status,
        })
      );
    },

    onClose: () => alert("Wait! Don't leave :("),
  };

  const handlePolicy = () => {
    setPolicy(!policy);
  };

  //5k box text............................................................
  const textToRenderInSub = () => {
    if (
      (log?.trial_period == true && log?.initial_sub_amount == 5000) ||
      (log?.initial_sub_amount == 50 && timeDifferenceDays !== 0)
    ) {
      return (
        <p
          className={
            acctype == "Free"
              ? "text-black lg:text-sm sm:text-[11px] sm:leading-[13px]"
              : "text-white lg:text-sm sm:text-[11px] sm:leading-[13px]"
          }
        >
          Trial ends in {timeDifferenceDays} day(s)
        </p>
      );
    } else if (
      log?.trial_period == false &&
      log?.initial_sub_amount == 5000 &&
      timeDifferenceDays == 0
    ) {
      return (
        <p
          className={
            acctype == "Free"
              ? "text-black lg:text-sm sm:text-[11px] sm:leading-[13px]"
              : "text-white lg:text-sm sm:text-[11px] sm:leading-[13px]"
          }
        >
          Kindly Make a repayment to continue
        </p>
      );
    } else if (log?.trial_period == true && log?.initial_sub_amount == 0) {
      return (
        <p
          className={
            acctype == "Free"
              ? "text-black lg:text-sm sm:text-[11px] sm:leading-[13px]"
              : "text-white lg:text-sm sm:text-[11px] sm:leading-[13px]"
          }
        >
          Get unlimited benefits with our monthly plan!
        </p>
      );
    } else if (
      (log?.trial_period == false &&
        log?.initial_sub_amount == 5000 &&
        timeDifferenceDays == 0) ||
      timeDifferenceDays < 0
    ) {
      return (
        <p
          className={
            acctype == "Free"
              ? "text-black lg:text-sm sm:text-[11px] sm:leading-[13px]"
              : "text-white lg:text-sm sm:text-[11px] sm:leading-[13px]"
          }
        >
          Kindly renew to enjoy our services
        </p>
      );
    } else {
      return (
        <p
          className={
            acctype == "Free"
              ? "text-black lg:text-sm sm:text-[11px] sm:leading-[13px]"
              : "text-white lg:text-sm sm:text-[11px] sm:leading-[13px]"
          }
        >
          Get unlimited benefits with our monthly plan!
        </p>
      );
    }
  };
  //5k box button..................................................................
  const textButtonInSub = () => {
    return (
      <PaystackButton
        {...componentProps}
        className={
          acctype == "Free"
            ? "lg:mt-10 sm:mt-5 lg:h-10 sm:h-8 font-medium font-sand border-2 text-black border-black px-2 rounded-[200px] lg:text-sm"
            : "text-white border-white border-2 rounded-[200px] px-2"
        }
      />
    );
  };
  //.................................................................................
  const textToRenderInSubB = () => {
    if (log?.trial_period == true && log?.initial_sub_amount == 35000) {
      return (
        <p
          className={
            acctype == "Free"
              ? "text-black"
              : "text-white lg:text-sm sm:text-[11px] sm:leading-[13px]"
          }
        >
          You are on our full term plan
        </p>
      );
    } else if (log?.trial_period == false && log?.initial_sub_amount == 35000) {
      return (
        <p
          className={
            acctype == "Free"
              ? "text-black"
              : "text-white lg:text-sm sm:text-[11px] sm:leading-[13px]"
          }
        >
          You are on our full term plan
        </p>
      );
    } else if (
      (log?.trial_period == true && log?.initial_sub_amount == 0) ||
      log?.initial_sub_amount == 5000
    ) {
      return (
        <p
          className={
            acctype == "Free"
              ? "text-black"
              : "text-white lg:text-sm sm:text-[11px] sm:leading-[13px]"
          }
        >
          Peace of Mind for Your Entire Pregnancy
        </p>
      );
    }
  };

  //35k box button..........................
  const textButtonInSubB = () => {
    return (
      <PaystackButton
        {...higerPayment}
        className={
          acctype == "Free"
            ? "border-black border-2 px-2 rounded-[200px] sm:mt-5 lg:h-10 sm:h-8"
            : "lg:mt-10 sm:mt-5 lg:h-10 sm:h-8 font-medium font-sand border-2 text-white border-white px-2 rounded-[200px] lg:text-sm"
        }
      />
    );
  };

  return (
    <div>
      <p className="mt-1 font-sand">Plan</p>
      <div className="grid lg:grid-cols-1  mt-5 sm:grid-cols-1 py-2">
        {/* <div
          className={
            acctype == "Free"
              ? "lg:h-[150px] sm:h-[120px] w-full bg-[#790b6327] rounded-md px-2"
              : "lg:h-[150px] sm:h-[120px] w-full bg-[#9a11b1] rounded-md px-2"
          }
        > */}
        {/* <div className="flex flex-row justify-between mt-2 ">
            <div className="basis-4/5  ">
              <p
                className={
                  acctype == "Free"
                    ? "font-bold font-sand lg:text-sm text-black sm:text-[12px]"
                    : "font-bold font-sand lg:text-sm text-white sm:text-[12px]"
                }
              >
                Monthly
              </p>
              {textToRenderInSub()}
              {textButtonInSub()}
            </div>

            <div className="basis-1/5 justify-end">
              <p
                className={
                  acctype == "Free"
                    ? "font-bold font-sand text-black sm:text-[12px] lg:text-sm"
                    : "text-white"
                }
              >
                &#8358;5,000/Monthly
              </p>
            </div>
          </div> */}
        {/* </div> */}
        <div
          className={
            acctype == "Free"
              ? "bg-preggifyGreen px-2 lg:h-[140px] sm:h-[120px] rounded-md px-2"
              : "lg:h-[250px] sm:h-[120px] w-full bg-alternateGreen rounded-md px-2"
          }
        >
          <div className="flex flex-row justify-between mt-2">
            <div className="basis-4/5 ">
              <p
                className={
                  acctype == "Free"
                    ? "text-black font-bold "
                    : "font-bold font-sand text-white sm:text-[12px] lg:text-sm"
                }
              >
                Full term
              </p>
              {textToRenderInSubB()}
              {textButtonInSubB()}
            </div>

            <div className="basis-1/5 justify-end">
              <p
                className={
                  acctype == "Free"
                    ? "font-bold font-sand text-black"
                    : "font-bold font-sand text-white lg:text-sm sm:text-[12px] "
                }
              >
                &#8358;50,000
              </p>
            </div>
          </div>
        </div>
        {policy == true ? (
          <p
            className="font-sand font-semibold text-preggifyPurple underline cursor-pointer"
            onClick={(e) => handlePolicy()}
          >
            Close refund policy
          </p>
        ) : (
          <p
            className="font-sand font-semibold text-preggifyPurple underline cursor-pointer"
            onClick={(e) => handlePolicy()}
          >
            Read our refund policy
          </p>
        )}
      </div>
      <Collapse isOpened={policy}>
        {/* <p>
          <b>1. Monthly Subscription:</b> <br /> (a) If a customer cancels their
          subscription within the first 7 days of the billing cycle due to poor
          service from our end, a full refund will be issued but if otherwise,
          it will be cancelled without refund.
          <br />
          (b) If a customer cancels their subscription after the first 7 days of
          the billing cycle, no refund will be issued for that month, but the
          subscription will be canceled and no further charges will be made.
        </p> */}
        {/* <br />
        <hr /> */}
        <p>
          <b> Whole Pregnancy Subscription:</b> <br /> (a) If a customer cancels
          their whole pregnancy subscription within the first trimester (12
          weeks), a refund of 60% of the total subscription fee will be issued.{" "}
          <br /> (b) If a customer cancels their whole pregnancy subscription in
          the second trimester , a refund of 40% of the total subscription fee
          will be issued. <br /> (c) If a customer cancels their whole pregnancy
          subscription in the third trimester (28 weeks and beyond), no refund
          will be issued, but the subscription will be cancelled.
        </p>
        <br />
        <p>
          <b>All refunds takes 5-10 days</b>
        </p>
        {/* <img src="nyancat.gif" /> */}
      </Collapse>
    </div>
  );
};

export default Subscription;
