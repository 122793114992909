import { useState, useEffect } from "react";
import Profile from "./Settings/Profile";
import MensturalSettings from "./Settings/MensturalSettings";
import Partners from "./Settings/partnerSettings";
import { useLocation } from "react-router-dom";
import Subscription from "./Settings/Subscription";
import { subscriptionDetails, payment_reload } from "../../reducers/user";
import { useDispatch, useSelector } from "react-redux";

const Settings = () => {
  const dispatch = useDispatch();
  const reload = useSelector(payment_reload);
  const [incomingState, seIncomingState] = useState();
  const [step, setStep] = useState();
  // console.log(reload);

  const get_step = useLocation();
  useEffect(() => {
    dispatch(subscriptionDetails());
    if (get_step.state) {
      setStep(get_step.state);
    } else {
      setStep(2);
    }
  }, [get_step, reload]);

  const settingSteps = () => {
    switch (step) {
      case 1:
        return <Profile />;

      case 2:
        return <MensturalSettings />;
      case 3:
        return <Partners />;
      case 4:
        return <Subscription />;
      // default:
      //   <p>End</p>;
    }
  };
  return (
    <>
      <div className="mt-10 px-5 py-7 ">
        <p className="font-sand lg:text-xl sm:text-base font-bold ml-4 mr-4 mb-4">
          Settings
        </p>
        <div className="flex lg:flex-row sm:flex-col px-10 font-normal font-sand  text-sm shadow-md bg-white rounded-md lg:gap-[30px] sm:gap-[5px] py-4 ">
          <div className="lg:basis-[20%] sm:basis-full">
            <div
              className={`${
                step == 1
                  ? "bg-[#790b63] text-white px-2 rounded-md mt-2 py-3 font-semibold"
                  : "mt-2 mb-2 py-2 font-semibold"
              }`}
            >
              <button onClick={() => setStep(1)}>Edit profile</button>
            </div>
            <div
              className={`${
                step == 2
                  ? "bg-[#790b63] text-white px-2 rounded-md py-3 font-semibold"
                  : "mt-2 mb-2 py-2 font-semibold"
              }`}
            >
              <button onClick={() => setStep(2)}>Set Pregnancy History</button>
            </div>
            {/* <div className="mt-2 mb-2 py-1">
              <button>Security</button>
            </div> */}
            {/* <div className="mt-2 mb-2 py-1">
              <button>Subscription</button>
            </div> */}
            {/* <div className="mt-2 mb-2 py-1">
              <button>Notifications</button>
            </div> */}
            <div
              className={`${
                step == 3
                  ? "bg-[#790b63] text-white px-2 rounded-md mt-2 py-3 font-semibold"
                  : "mt-2 mb-2 py-1 font-semibold"
              }`}
            >
              <button onClick={() => setStep(3)}>Add partners</button>
            </div>
            <div
              className={`${
                step == 4
                  ? "bg-[#790b63] text-white px-2 rounded-md mt-2 py-3 font-semibold"
                  : "mt-2 mb-2 py-1 font-semibold"
              }`}
            >
              <button onClick={() => setStep(4)}>Manage subscription</button>
            </div>

            <hr className=" border-gray-300" />
          </div>
          {/* End of basis... */}
          <div className="lg:basis-[80%] sm:basis-full lg:mt-4 sm:mt-1  lg:px-10 sm:px-0">
            {settingSteps()}
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
