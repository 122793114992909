import { useState } from "react";
import ify from "../../Imgs/ify.jpg";
const Medication = () => {
  const width = {};
  return (
    <>
      <div className=" px-5 mt-5 font-sand">
        <div className="">
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-white uppercase bg-preggifyPurple">
                <tr>
                  <th scope="col" class="px-6 py-3">
                    Prescribed by
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Drug name
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Frequency
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Duration
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Start Date
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Usage status
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Completion Date
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <th
                    scope="row"
                    class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap"
                  >
                    <img class="w-10 h-10 rounded-full" src={ify} alt="" />
                    <div class="pl-3">
                      <div class="text-base font-semibold">DR IFY</div>
                      <div class="text-sm font-normal text-black">
                        Available on preggify
                      </div>
                    </div>
                  </th>
                  <td class="px-6 py-4">PREGGY PREGGY</td>
                  <td class="px-6 py-4">
                    <div class="flex items-center">
                      {/* <div class="h-2.5 w-2.5 rounded-full bg-green-500 mr-2"></div>{" "} */}
                      1 tablet/day
                    </div>
                  </td>
                  <td class="px-6 py-4">30 Days</td>
                  <td class="px-6 py-4">Jul 01 2023</td>
                  <td className="px-6 py-4">
                    <div class="w-full  bg-gray-200 rounded-full h-2.5  dark:bg-gray-700">
                      <div
                        class="bg-preggifyGreen h-2.5 rounded-full"
                        style={{ width: 20 + "%" }}
                      ></div>
                    </div>
                  </td>
                  <td className="px-6 py-4">july 3rd 2023</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Medication;
