import { Carousel, Card, Avatar, Button } from "flowbite-react";

import testimonial_pics from "../Imgs/ify.jpg";
import React, { useState, useEffect } from "react";

import Datepicker from "flowbite-datepicker/Datepicker";
import { PaystackButton } from "react-paystack";
import {
  register,
  info,
  loaderState,
  subType,
  val_to_pay,
  subuser,
} from "../reducers/registration";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../Imgs/logo.png";
import herlogo from "../Imgs/HERCONOMY LOGO.png";
import { Link } from "react-router-dom";
import reportWebVitals from "./../reportWebVitals";
const SignupHerConomy = () => {
  const message = useSelector(info);
  const loader = useSelector(loaderState);
  const sub = "premium";
  const initial_pay = useSelector(val_to_pay || 0);
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [first_name, setFirstName] = useState();
  const [last_name, setLastName] = useState();
  const [phone_number, setPhoneNumber] = useState();
  const [gender, setGender] = useState("");
  const [date_of_birth, setDateOfBirth] = useState("");
  const [password, setPassword] = useState("");
  const [ready, setReady] = useState(false);
  // const [subscription_type, setSubscriptionType] = useState(sub);
  const kie = process.env.REACT_APP_MY_STACK_KEY;

  const [confirmPass, setConfirmPass] = useState();
  // const [val, setVal] = useState(val_to_pay);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  //   console.log("sub", kie);/
  // console.log("jojo", kie);

  const handleSubmit = (e) => {
    var payable = "";
    if (initial_pay !== 0) {
      payable = initial_pay;
    } else {
      payable = 0;
    }
    // console.log("Clicked");
    e.preventDefault();
    const regData = {
      email,
      first_name,
      last_name,
      date_of_birth,
      phone_number,
      gender,
      password,
      subscription_type: "premium",
      initial_sub_amount: 45000,
    };
    dispatch(register(regData));
  };

  const componentProps = {
    className: "text-white font-sand font-normal",
    email: email,
    amount: 45000 * 100,
    metadata: {
      name: first_name + " " + last_name,
      phone: phone_number,
    },
    publicKey: `${process.env.REACT_APP_MY_STACK_KEY}`,
    text: "Subscribe Now",
    onSuccess: (reference) => {
      alert("Thanks for doing business with us! Come back soon!!", reference);
      dispatch(
        subuser({
          ref: reference,
          email,
          special_member_from: "Herconomy",
          first_name,
          last_name,
          date_of_birth,
          phone_number,
          gender,
          password,
          subscription_type: sub,
          initial_sub_amount: 45000,
        })
      );
      // console.log("reference", reference);
    },

    onClose: () => alert("Wait! Don't leave :("),
  };

  useEffect(() => {
    if (message === "Registration successful" || message === "user saved") {
      navigate("/login");
    }
  }, [message, navigate]);

  // console.log("info", message);

  const currentStep = () => {
    if (step === 1) {
      return (
        <>
          <div className="lg:columns-2 sm:column-1">
            <div className="">
              <label
                htmlFor="First name"
                className="block mb-2 text-sm font-medium font-sand text-gray-90"
              >
                First name
              </label>
              <input
                onChange={(e) => setFirstName(e.target.value)}
                name="first_name"
                type="text"
                className=" border border-[#808080] font-normal text-gray-900 text-sm rounded-lg font-sand focus:border-[#808080] block w-full p-2.5"
                placeholder="First Name"
                required
              />
            </div>
            <div className="mb-3  mt-3">
              <label
                htmlFor="lastname"
                className="block mb-2 text-sm font-medium text-gray-900 font-sand dark:text-white"
              >
                Last Name
              </label>
              <input
                onChange={(e) => setLastName(e.target.value)}
                type="text"
                name="last_name"
                className=" border border-[#808080] font-sand font-normal text-gray-900 text-sm rounded-lg  focus:border-[#808080] block w-full p-2.5"
                placeholder="Last Name"
                required
              />
            </div>
          </div>
          {/*  End of col*/}
          <div className="lg:columns-2 sm:column-1">
            <div className="">
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium font-sand text-gray-900 dark:text-white"
              >
                Email
              </label>
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                name="email"
                className=" border border-[#808080] font-normal font-sand text-gray-900 text-sm rounded-lg  focus:border-[#808080] block w-full p-2.5"
                placeholder="Email"
                required
              />
            </div>
            <div className="">
              <label
                htmlFor="phone"
                className="block mb-2 text-sm font-medium font-sand text-gray-900 dark:text-white"
              >
                Phone number
              </label>
              <input
                onChange={(e) => setPhoneNumber(e.target.value)}
                type="text"
                name="phone"
                className=" border border-[#808080] font-normal font-sand text-gray-900 text-sm rounded-lg  focus:border-[#808080] block w-full p-2.5"
                placeholder="phone"
                required
              />
            </div>
          </div>
          <div className="columns-2">
            <label
              htmlFor="countries"
              className="block  text-sm font-sand  mb-4 font-medium text-gray-900 dark:text-white"
            >
              Gender
            </label>
            <select
              onChange={(e) => setGender(e.target.value)}
              name="gender"
              id="countries"
              className=" border border-[#808080] mt-2 text-gray-900 font-sand text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value="">Choose a Gender</option>
              <option value="Female">Female</option>
              <option value="Male">Male</option>
            </select>
            <div>
              <label
                htmlFor="countries"
                className="block  text-sm font-sand  font-medium text-gray-900 dark:text-white"
              >
                Date of Birth
              </label>
              <input
                onChange={(e) => setDateOfBirth(e.target.value)}
                // name="date_of_birth"
                // id="datepickerId"
                type="date"
                className="bg-white border-[#808080] font-sand mt-4  text-gray-900 text-sm rounded-lg focus:bg-white focus:border-[#808080] block w-full pl-10 p-2.5 "
                required
              />
            </div>
          </div>

          {/* End of col */}
          <div className="columns-2">
            <div className="mb-1">
              <label
                htmlFor="First name"
                className="block mb-2 text-sm font-medium font-sand text-gray-90"
              >
                Password
              </label>
              <input
                onChange={(e) => setPassword(e.target.value)}
                name="first_name"
                type="password"
                className=" border border-[#808080] font-normal text-gray-900 text-sm rounded-lg font-sand focus:border-[#808080] block w-full p-2.5"
                placeholder="*******"
                required
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 font-sand dark:text-white"
              >
                Confirm password
              </label>
              <input
                onChange={(e) => setConfirmPass(e.target.value)}
                type="password"
                name="last_name"
                className=" border border-[#808080] font-sand font-normal text-gray-900 text-sm rounded-lg  focus:border-[#808080] block w-full p-2.5"
                placeholder="*******"
                required
              />
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div className=" px-4 flex  items-start justify-center h-screen  bg-[#FFF4D6]">
        <div className="flex  lg:flex-row sm:flex-col shadow-2xl shadow-[#47618735]  bg-white py-4 justify-between max-w-4xl px-4 rounded-lg mt-5 mb-3 ">
          {/* Start of first 1/2 */}
          <div className="flex  flex-wrap ">
            <div className="box-content lg:inline-flex bg-gradient-to-br from-transparent to-[#] bg-herconomy h-screen w-[100%] sm:hidden  rounded-lg items-center justify-between">
              <div className="px-7 ">
                <h1 className=" font-sand font-bold text-3xl text-black mt-5 ">
                  Start your pregnancy journey with Preggify.
                </h1>
                <p className="font-sand text-sm font-normal mt-4 text-black">
                  {/* Discover the world of digital pregnancy care */}
                  Gain access to Professional pregnancy care at your fingertips
                  with Preggify's 24/7 support!
                </p>
                <div className="lg:h-60 sm:h-10">
                  <Carousel
                    aria-controls={false}
                    leftControl={false}
                    rightControl={false}
                    slideInterval={6000}
                  >
                    <div className="max-w-sm bg-[#FFF4D6]  p-3 rounded-md h-42">
                      <h5 className="text-xl font-bold tracking-tight font-sand text-black">
                        Testimonial
                      </h5>
                      <p className="font-normal text-sm text-black font-sand ">
                        -Mrs Temi Johnson- <br /> The Doctor was always
                        available whenever I reached out. Even times when I
                        thought I was disturbing her and stayed away, she would
                        reach out to check on me and “our” baby(like she always
                        says). Being in a different country was never a barrier
                        for her
                      </p>
                      {/* <img
                        className="w-10 h-10 rounded-md mt-2"
                        src={testimonial_pics}
                        alt="Default avatar"
                      ></img> */}
                      {/* <span>Sghgh</span> */}
                    </div>
                    <div className="max-w-sm bg-[#808080]  p-3 rounded-md h-42">
                      <h5 className="text-xl font-bold tracking-tight font-sand text-white ">
                        Testimonial
                      </h5>
                      <p className="font-normal text-sm text-white font-sand">
                        -Dr Mojisola OGUNLAKIN-
                        <br />
                        The classes are structured to teach in very simple terms
                        such that you get to understand complex medical
                        conditions and terms in very basic words. As a doctor, I
                        can testify that the class is in-depth and very
                        comprehensive.
                      </p>
                      {/* <img
                        className="w-10 h-10 rounded-md mt-2"
                        src={testimonial_pics}
                        alt="Default avatar"
                      ></img> */}
                      {/* <span>Sghgh</span> */}
                    </div>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
          {/* End of first 1/2 */}
          {/* Start of first 1/2 */}
          <div className="flex flex-wrap items-center  justify-center">
            <div>
              <div className="columns-2">
                <div>
                  <img src={logo} className="h-12" alt="" />
                </div>
                <div>
                  <img src={herlogo} className="h-8" alt="" />
                </div>
              </div>

              <Card className="shadow-none border-none w-full">
                <div className="flex flex-row ">
                  <h1 className="flex font-sand font-bold text-2xl">Sign up</h1>{" "}
                </div>
                <p className="text-[#808080]">
                  10% Discount has been applied specially for you by Herconomy
                </p>

                <p className="font-sand text-sm mt-2 text-black">
                  Kindly fill in the required information to become a member
                  today
                </p>

                <p className="font-normal font-sand text-sm text-gray-700 dark:text-gray-400"></p>

                {currentStep()}
                <div>
                  <input
                    type="checkbox"
                    className="rounded-md"
                    name="terms"
                    onClick={() => setReady(!ready)}
                    id=""
                  />
                  <label htmlFor="" className="mx-2 font-sand text-sm">
                    Agree to our terms and condition .
                    <span>
                      <a
                        className="text-red-700"
                        href="https://preggify.com/refundpolicy"
                        target="_blank"
                      >
                        {" "}
                        Click here to read
                      </a>
                    </span>
                  </label>
                </div>

                {gender !== "" &&
                confirmPass !== "" &&
                sub == "premium" &&
                password !== "" &&
                date_of_birth !== "" &&
                email !== "" &&
                first_name !== "" &&
                last_name !== "" &&
                phone_number !== "" &&
                ready !== false &&
                confirmPass == password ? (
                  <PaystackButton
                    {...componentProps}
                    className={
                      "lg:mt-10 sm:mt-5 lg:h-10 sm:h-8 font-medium font-sand border-2 text-white bg-herconomy px-2 rounded-[200px] lg:text-sm"
                    }
                  />
                ) : gender !== "" &&
                  password !== "" &&
                  date_of_birth !== "" &&
                  email !== "" &&
                  first_name !== "" &&
                  last_name !== "" &&
                  phone_number !== "" &&
                  confirmPass !== "" &&
                  ready !== false &&
                  confirmPass == password &&
                  sub !== "premium" ? (
                  <button
                    onClick={handleSubmit}
                    className="rounded-full bg-preggifyPurple p-2 text-white px-2 "
                  >
                    <span className="px-2 py-2 font-sand text-sm font-medium">
                      Register now
                    </span>
                    <svg
                      aria-hidden="true"
                      role="status"
                      className={
                        loader == true
                          ? "inline w-4 h-4 mr-3 text-white animate-spin"
                          : "inline w-0 h-0 mr-3 text-white"
                      }
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                  </button>
                ) : (
                  <p className="font-sand text-sm text-black">
                    Fill in all details to proceed.
                    <br />
                    {/* {sub == "premium" ? (
                      <b>
                        Please note that &#8358;50 will be deducted initially
                        for this action
                      </b>
                    ) : (
                      ""
                    )} */}
                  </p>
                )}

                <p className="font-sand text-sm font-bold">
                  Already a member?
                  <span>
                    <Link to={"/login"} className="text-herconomy">
                      {" "}
                      Login
                    </Link>
                  </span>
                </p>
              </Card>
            </div>
          </div>
        </div>
        {/* End of second 1/2 */}
      </div>
    </>
  );
};

export default SignupHerConomy;
