import ify from "../../Imgs/ify.png";
import {
  MdOutlineDashboard,
  MdOutlineSpaceDashboard,
  MdMiscellaneousServices,
} from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineSetting, AiOutlineArrowLeft } from "react-icons/ai";
import { GiDoctorFace, GiNurseFemale } from "react-icons/gi";
import { BsHospital } from "react-icons/bs";
import { GoChevronRight } from "react-icons/go";
import {
  AiOutlineLogout,
  AiOutlineArrowsAlt,
  AiFillStar,
} from "react-icons/ai";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  fname,
  menuState,
  setmenu,
  subType,
  setmenuMobile,
} from "../../reducers/user";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";

const SideBar = () => {
  const dispatch = useDispatch();
  const myName = useSelector(fname);
  const sub = useSelector(subType);
  const navigate = useNavigate();
  const [premiumOrFree, setPremiunOrFree] = useState();

  const destroySession = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = "/login";

    // navigate("/login", { replace: false });
  };

  const menuStatus = useSelector(menuState);
  // console.log(window.innerWidth);

  const handleMenu = () => {
    var windowSize = window.innerWidth;
    if (windowSize < 800) {
      dispatch(setmenu());
    }
  };

  useEffect(() => {
    var windowSize = window.innerWidth;
    if (windowSize < 800) {
      dispatch(setmenuMobile());
    }
  }, []);

  return (
    <div
      className={`${
        menuStatus
          ? "inline-block lg:h-screen w-[15rem] opacity-100 fixed sm:h-[100vh] z-10 bg-gradient-to-bl from-[#ffffff]  to-[#f5fff8] transition-all ease-out duration-300 border-r-2 border-[#00000018]"
          : "w-[0rem] opacity-0 transition-all ease-out duration-300"
      } `}
    >
      <div className=" h-60 w-full bg-white  relative items-center flex flex-col justify-center ">
        <div className=" w-full text-white justify-end flex">
          <div className="lg:hidden sm:inline-flex p-2 px-3">
            <GiHamburgerMenu
              size={"30px"}
              color="#000000"
              onClick={() => handleMenu()}
            />
          </div>
        </div>
        <div>
          <div className=" h-24 w-24 justify-center flex items-center rounded-full bg-preggifyGreen m-auto">
            <h1 className="font-sand text-[50px] font-semibold text-white">
              {myName ? myName[0] : ""}
            </h1>
          </div>
          <p className="font-sand text-lg font-bold text-center mt-2 ">
            {myName}
          </p>

          <p className="font-sand relative text-sm font-normal text-center capitalize ">
            {sub == "premium" ? (
              <span className="absolute -left-[24px] -top-[9px]">
                <AiFillStar className="pl-3" color="	#FFD700" size={40} />
              </span>
            ) : (
              ""
            )}

            {sub}
          </p>
        </div>
      </div>
      {/* Start of navigation */}
      <div className="">
        <NavLink
          to={"/userHome/Dashboard"}
          className=" flex items-center justify-between cursor-pointer gap-[10px] px-4 py-3 border-y-[1px] transition-all duration-200 ease-in-out hover:bg-[#96e0954a]"
        >
          <div
            className="flex items-center  gap-[10px]"
            onClick={() => handleMenu()}
          >
            <MdOutlineSpaceDashboard
              className="pl-3"
              color="#18111c"
              size={"40px"}
            />
            <div className="font-sand font-normal text-sm text-[#18111c]">
              Dashboard
            </div>
          </div>

          <GoChevronRight className="pl-3" color="#18111c" size={20} />
        </NavLink>
        <NavLink
          to={"/userHome/services"}
          className=" flex items-center justify-between cursor-pointer gap-[10px] px-4 py-3 border-b-[1px] transition-all duration-200 ease-in-out hover:bg-[#96e0954a]"
        >
          <div
            className="flex items-center gap-[10px]"
            onClick={() => handleMenu()}
          >
            <MdMiscellaneousServices
              className="pl-3"
              color="#18111c"
              size={"40px"}
            />
            <div className="font-sand font-normal  text-sm text-[#18111c]">
              Services
            </div>
          </div>

          <GoChevronRight className="pl-3" color="#18111c" size={20} />
        </NavLink>
        <NavLink
          to={"/userHome/Settings"}
          className=" flex items-center justify-between cursor-pointer gap-[10px] px-4 py-3 border-b-[1px] transition-all duration-200 ease-in-out hover:bg-[#96e0954a]"
        >
          <div
            className="flex items-center gap-[10px]"
            onClick={() => handleMenu()}
          >
            <AiOutlineSetting className="pl-3" color="#18111c" size={"40px"} />
            <div className="font-sand font-normal  text-sm text-[#18111c]">
              Settings
            </div>
          </div>

          <GoChevronRight className="pl-3" color="#18111c" size={"20px"} />
        </NavLink>
        <div
          // to={"/userHome/Coming"}
          className=" flex items-center justify-between cursor-pointer gap-[10px] px-4 py-3 border-b-[1px] transition-all duration-200 ease-in-out hover:bg-[#30303029]"
        >
          <div
            className="flex items-center gap-[10px]"
            onClick={() => handleMenu()}
          >
            <GiNurseFemale className="pl-3" color="#18111c" size={"40px"} />
            <p className="font-sand font-normal text-sm leading-[20px] text-[#18111c]">
              Speak to a doctor coming Soon!
            </p>
          </div>

          {/* <GoChevronRight className="pl-3" color="#18111c" size={23} /> */}
        </div>
        <div
          // to={"/userHome/ComingSoon"}
          className=" flex items-center justify-between cursor-pointer gap-[10px]  px-4 py-3 border-b-[1px] transition-all duration-200 ease-in-out hover:bg-[#30303029]"
        >
          <div
            className="flex items-center gap-[10px]"
            onClick={() => handleMenu()}
          >
            <BsHospital className="pl-3" color="#18111c" size={"40px"} />
            <p className="font-sand font-normal   text-sm text-[#18111c]">
              Find a Hospital coming soon!
            </p>
          </div>

          {/* <GoChevronRight className="pl-3" color="#18111c" size={23} /> */}
        </div>

        <div className=" flex items-center justify-between cursor-pointer gap-[10px] px-4 py-3 border-b-[1px] transition-all duration-200 ease-in-out hover:bg-[#96e0954a]">
          <div
            className="flex items-center gap-[10px]"
            onClick={() => handleMenu()}
          >
            <AiOutlineLogout className="pl-3" color="#18111c" size={"40px"} />
            <div
              onClick={destroySession}
              className="font-sand font-normal  text-sm text-[#18111c]"
            >
              Logout
            </div>
          </div>

          {/* <GoChevronRight className="pl-3" color="#18111c" size={20} /> */}
        </div>
        {/* <button
          className="fixed lg:hidden z-90 bottom-10 right-44 bg-preggifyPurple w-10 h-10 rounded-full drop-shadow-lg flex justify-center items-center text-white text-4xl hover:bg-preggifyGreen duration-300"
          onClick={toggleSideNav}
        >
          <span class="text-center">
            <AiOutlineArrowLeft
              className="pl-3"
              color="#ffffff"
              size={"40px"}
            />
          </span>
        </button> */}

        {/* <div className=" flex items-center justify-between cursor-pointer gap-[15px] py-[3px] border-b-[1px]">
          <div className="flex items-center gap-[10px]">
            <AiOutlineArrowsAlt
              className="pl-3"
              color="#790b63"
              size={"40px"}
            />
            <NavLink
              to=""
              onClick={destroySession}
              className="font-sand font-normal  text-sm text-preggifyPurple"
            >
              Logout
            </NavLink>
          </div>

          <GoChevronRight className="pl-3" color="#790b63" size={"20px"} />
        </div> */}
      </div>
    </div>
  );
};

export default SideBar;
