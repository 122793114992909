import { useLocation } from "react-router-dom";
const PlayScreen = () => {
  const location = useLocation();
  const link = location.state;

  return (
    <>
      <div className="px-5 mt-5 bg-white shadow-md">
        <video
          className="px-2 py-2"
          controls
          controlsList="nodownload"
          width="100%"
          height="auto"
          src={`https://${link}`}
        />
      </div>
    </>
  );
};

export default PlayScreen;
