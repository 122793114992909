import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const getToks = sessionStorage.getItem("tokken");
//changed from digital ocean.....
const BASEURL_LIVE = 'https://preggifyservices.preggify.com/preggify/v1/'

const URL = `${BASEURL_LIVE}updatelmp`
const URL_B = `${BASEURL_LIVE}dashboard`
const URL_C = `${BASEURL_LIVE}freetips`
const URL_D = `${BASEURL_LIVE}getpaidtips`
const URL_E = `${BASEURL_LIVE}getaffirmations`
const URL_F = `${BASEURL_LIVE}managedepression`
const URL_G = `${BASEURL_LIVE}gettest`
const URL_H = `${BASEURL_LIVE}registerpartners`
const URL_I = `${BASEURL_LIVE}updateprofile`
const URL_J = `${BASEURL_LIVE}mysubdetails`
const URL_K = `${BASEURL_LIVE}confirmsubscription`
const URL_L = `${BASEURL_LIVE}getpregnancyvideos`
const URL_M = `${BASEURL_LIVE}turnofsub`

export const lmpConfig = createAsyncThunk(
      "user/setlmp",
      async (args, { rejectWithValue },) => {
            try {
                  const { data } = await axios.put(URL, args, {
                        headers: {
                              Authorization: `Bearer ${JSON.parse(getToks)}`
                        }
                  });
                  toast.success("Data Saved")

                  return data
            } catch (err) {
                  rejectWithValue(err.response.data);
            }
      }
);

export const subscriptionDetails = createAsyncThunk(
      "user/subscriptiondetails",
      async (args, { rejectWithValue },) => {
            try {
                  const { data } = await axios.post(URL_J, args, {
                        headers: {
                              Authorization: `Bearer ${JSON.parse(getToks)}`
                        }
                  });
                  // toast.success("Data Saved")
                  if (data.data) {
                        return data.data[0].payment_history
                  }
                  return data

            } catch (err) {
                  rejectWithValue(err.response.data);
            }
      }
);
//update subscription details..
export const update_subscription_status = createAsyncThunk(
      "user/updatesubscriptiondetails",
      async (args, { rejectWithValue },) => {
            try {
                  const { data } = await axios.put(URL_K, args, {
                        headers: {
                              Authorization: `Bearer ${JSON.parse(getToks)}`
                        }
                  });
                  toast.success("Subscription updated")
                  return data

            } catch (err) {
                  rejectWithValue(err.response.data);
            }
      }
);
//add this..
export const update_profile = createAsyncThunk(
      "user/updateprofile",
      async (args, { rejectWithValue },) => {
            try {
                  const { data } = await axios.put(URL_I, args, {
                        headers: {
                              Authorization: `Bearer ${JSON.parse(getToks)}`
                        }
                  });
                  toast.success("profile updated")

                  return data
            } catch (err) {
                  rejectWithValue(err.response.data);
            }
      }
);

export const fetchProfile = createAsyncThunk(
      "user/userData",
      async (args, { rejectWithValue }) => {
            try {
                  const { data } = await axios.post(URL_B, args, {
                        headers: {
                              Authorization: `Bearer ${JSON.parse(getToks)}`
                        }
                  });
                  return data;
            } catch (err) {
                  rejectWithValue(err.response.data);
            }
      }
);
export const fetchTips = createAsyncThunk(
      "Tips/FreeTips",
      async (args, { rejectWithValue }) => {
            try {

                  const { data } = await axios.post(URL_C, args);
                  return data.data[0]
            } catch (err) {
                  rejectWithValue(err.response.data);
            }
      }
);
export const fetchProTips = createAsyncThunk(
      "Tips/proTips",
      async (args, { rejectWithValue }) => {
            try {
                  const { data } = await axios.post(URL_D, args);
                  return data.data[0]
            } catch (err) {
                  rejectWithValue(err.response.data);
            }
      }
);
export const addPartner = createAsyncThunk(
      "partner/savePartner",
      async (args, { rejectWithValue }) => {
            try {
                  const { data } = await axios.post(URL_H, args, {
                        headers: {
                              Authorization: `Bearer ${JSON.parse(getToks)}`
                        }
                  });
                  // console.log("yep", data)
                  toast.success(data.message)
            } catch (err) {
                  toast.warn("please retry")
                  rejectWithValue(err.response.data);
            }
      }
);
export const fetchAffirmations = createAsyncThunk(
      "Affirm/Affirmations",
      async (args, { rejectWithValue }) => {
            try {
                  const { data } = await axios.get(URL_E);
                  return data.data[0]
                  // console.log("affirmation", data.data[0])
            } catch (err) {
                  rejectWithValue(err.response.data);
            }
      }
);
export const setDepressiontest = createAsyncThunk(
      "submit/DepressionTest",
      async (args, { rejectWithValue }) => {
            try {
                  const { data } = await axios.post(URL_F, args, {
                        headers: {
                              Authorization: `Bearer ${JSON.parse(getToks)}`
                        }
                  })
                  toast.success("Thanks for completing the test")
                  return data
            } catch (err) {
                  // toast.warn("please retry")
                  rejectWithValue(err.resonse.data)
            }
      })
export const getpregnancyvideos = createAsyncThunk(
      "get/pregnancyvideos",
      async (args, { rejectWithValue }) => {
            try {
                  const { data } = await axios.get(URL_L, {
                        headers: {
                              Authorization: `Bearer ${JSON.parse(getToks)}`
                        }
                  })
                  // toast.success("Thanks for completing the test")
                  return data
            } catch (err) {
                  // toast.warn("please retry")
                  rejectWithValue(err.resonse.data)
            }
      })
//changes
export const testResult = createAsyncThunk(
      "getTest/TestReult",
      async (args, { rejectWithValue }) => {
            try {

                  const { data } = await axios.get(`${URL_G}` + '/?user_id=' + args, {
                        headers: {
                              Authorization: `Bearer ${JSON.parse(getToks)}`
                        }
                  })
                  return data.data[0].Detector_test

            } catch (err) {
                  // toast.warn("please retry")
                  rejectWithValue(err.resonse.data)
            }
      })
export const check_sub = createAsyncThunk(
      "managesub/Subscriptions",
      async (args, { rejectWithValue }) => {
            try {
                  const { data } = await axios.put(URL_M, args, {
                        headers: {
                              Authorization: `Bearer ${JSON.parse(getToks)}`
                        }
                  })
                  return data
            } catch (err) {
                  // toast.warn("please retry")
                  rejectWithValue(err.resonse.data)
            }
      })

const userSlice = createSlice({
      name: "userprofile",
      initialState: {
            lmp: "",
            GA: "",
            EDD: "",
            loading: false,
            success: false,
            id: "",
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            subscription_type: "",
            trimester: "",
            commonChallanges: "",
            protips: "",
            trimester: "",
            content: "",
            procontent: "",
            affirmations: "",
            menu_status: true,
            depression_test: "",
            isOpen: true,
            profile_status: "",
            paymentHistory: "",
            reload_payment: false,
            //move to academy later
            pregnancy_videos: "",
            account_refresh: ""




      },
      reducers: {
            setID: (state, action) => {
                  state.id = action.payload
            },

            setmenu: (state, action) => {
                  state.menu_status = !state.menu_status
            },
            setmenuMobile: (state) => {
                  state.menu_status = false
            },
            setGA: (state, action) => {
                  const { GA } = action.payload
                  state.GA = action.payload
            },
            setEDD: (state, action) => {
                  state.EDD = action.payload
            },
            SetTrimester: (state, action) => {
                  state.trimester = action.payload
            },
            closeModal: (state, action) => {
                  state.isOpen = action.payload
            },
            setPremium: (state, action) => {
                  state.subscription_type = "premium"
            },
            setFree: (state, action) => {
                  state.subscription_type = "Free"
            },
            setNot_Pregnant: (state, action) => {
                  state.subscription_type = "Not_Pregnant"
            }
            // setLMP:(state,action)
      },
      extraReducers: (builder) => {
            builder.addCase(lmpConfig.pending, (state) => {
                  state.loading = true
            })
            builder.addCase(lmpConfig.fulfilled, (state, action) => {
                  const { lmp } = action.payload
                  state.lmp = lmp
                  state.loading = false
            })
            builder.addCase(lmpConfig.rejected, (state) => {
                  state.loading = false
                  state.success = false
            })

            builder.addCase(fetchProfile.pending, (state) => {
                  state.loading = true
                  state.success = false
            })
            builder.addCase(fetchProfile.fulfilled, (state, action) => {
                  const { data } = action.payload
                  state.lmp = data.last_menstural_period
                  state.firstName = data.first_name
                  state.lastName = data.last_name
                  state.email = data.email
                  state.phone = data.phone_number
                  state.profile_status = data.profile_status
                  state.subscription_type = data.subscription_type
                  state.loading = false
                  state.success = true

            })
            builder.addCase(fetchProfile.rejected, (state, action) => {
                  state.loading = false
                  state.success = false
            })
            builder.addCase(fetchTips.pending, (state, action) => {
                  state.loading = true
                  state.success = false
            })
            builder.addCase(fetchTips.fulfilled, (state, action) => {
                  const { trimester, common_challanges, tips, content } = action.payload
                  state.trimester = trimester
                  state.commonChallanges = common_challanges
                  state.content = content[0]
                  state.loading = false
                  // state.tips = tips
            })
            builder.addCase(fetchTips.rejected, (state, action) => {
                  state.loading = false
                  state.success = false
            })
            builder.addCase(fetchProTips.pending, (state) => {
                  state.loading = true

            })

            builder.addCase(fetchProTips.fulfilled, (state, action) => {
                  const { content, tips_now } = action.payload
                  state.procontent = content[0]
                  state.protips = tips_now[0]
                  state.loading = false

            })
            builder.addCase(fetchProTips.rejected, (state) => {
                  state.loading = false
                  state.success = false
            })
            builder.addCase(fetchAffirmations.pending, (state) => {
                  state.loading = true
                  state.success = false
            })
            builder.addCase(fetchAffirmations.fulfilled, (state, action) => {
                  const { affirmations } = action.payload
                  state.loading = false
                  state.affirmations = affirmations[0]
                  state.success = true
            })
            builder.addCase(fetchAffirmations.rejected, (state) => {
                  state.loading = false
                  state.success = false
            })
            builder.addCase(setDepressiontest.pending, (state) => {
                  state.loading = true
            })
            builder.addCase(setDepressiontest.fulfilled, (state) => {
                  state.loading = false
                  state.success = true
            })
            builder.addCase(setDepressiontest.rejected, (state) => {
                  state.loading = false
                  state.success = false
            })
            builder.addCase(testResult.pending, (state) => {
                  state.loading = true
                  state.success = false
            })
            builder.addCase(testResult.fulfilled, (state, action) => {
                  state.loading = false
                  state.success = false
                  state.depression_test = action.payload
            })
            builder.addCase(testResult.rejected, (state) => {
                  state.loading = false
                  state.success = false
            })

            builder.addCase(addPartner.pending, (state, action) => {
                  state.loading = true
            })
            builder.addCase(addPartner.fulfilled, (state, action) => {
                  state.loading = false

            })
            builder.addCase(addPartner.rejected, (state, action) => {
                  state.loading = false
                  state.success = true
            })
            builder.addCase(subscriptionDetails.pending, (state, action) => {
                  state.loading = true
            })
            builder.addCase(subscriptionDetails.fulfilled, (state, action) => {
                  state.loading = false
                  state.paymentHistory = action.payload
            })

            builder.addCase(subscriptionDetails.rejected, (state, action) => {
                  state.loading = false
            })
            builder.addCase(update_subscription_status.pending, (state, action) => {
                  state.loading = true

            })
            builder.addCase(update_subscription_status.fulfilled, (state, action) => {
                  state.loading = false
                  // state.account_refresh = "refresh"
                  // state.reload_payment = true

            })
            builder.addCase(update_subscription_status.rejected, (state, action) => {
                  state.loading = false
            })
            builder.addCase(getpregnancyvideos.pending, (state) => {
                  state.loading = true

            })

            builder.addCase(getpregnancyvideos.fulfilled, (state, action) => {
                  const { data } = action.payload
                  state.pregnancy_videos = data
                  state.loading = false
            })
            builder.addCase(getpregnancyvideos.rejected, (state) => {
                  state.loading = false
            })

            builder.addCase(check_sub.pending, (state) => {
                  state.loading = true
            })
            builder.addCase(check_sub.fulfilled, (state, action) => {
                  state.loading = false
            })
            builder.addCase(check_sub.rejected, (state) => {
                  state.loading = false
            })

      }
})


export const lastCycle = (state) => state.user.lmp
export const subType = (state) => state.user.subscription_type
export const fname = (state) => state.user.firstName
export const myID = (state) => state.user.id
export const GANOW = (state) => state.user.GA
export const trimesterVal = (state) => state.user.trimester
export const challanges = (state) => state.user.commonChallanges
export const contents = (state) => state.user.content
export const protip = (state) => state.user.protips
export const procont = (state) => state.user.procontent
export const affirmation = (state) => state.user.affirmations
export const menuState = (state) => state.user.menu_status
export const loading = (state) => state.user.loading
export const test = (state) => state.user.depression_test
export const firstName = (state) => state.user.firstName
export const lastName = (state) => state.user.lastName
export const email = (state) => state.user.email
export const phone = (state) => state.user.phone
export const profile_stat = (state) => state.user.profile_status
export const modalCheck = (state) => state.user.isOpen
export const paymentRecords = (state) => state.user.paymentHistory
export const payment_reload = (state) => state.user.reload_payment
export const pregnancy_vid = (state) => state.user.pregnancy_videos
export const refresher = (state) => state.user.account_refresh
// export const test = (state) => state.user.loading

export default userSlice.reducer
export const { setID, setEDD, setGA, SetTrimester, setmenu, setmenuMobile, closeModal, setPremium, setFree } = userSlice.actions