import React, { useState } from "react";
import NavBar from "./Navbar";
import { Link } from "react-router-dom";
import thanks from "../Imgs/thanks.svg"

const Thankyou = () => {
   return (
     <div className="h-[100vh] bg-[#FDF2FF]">
       <NavBar />
       <div className=" flex flex-col justify-center items-center gap-2 font-sand pt-10 text-center px-6">
         <span className="text-3xl font-bold text-alternateGreen">
           Thank you for joining our waitlist - stay tuned!
         </span>
         <span className="md:text-base text-sm">
           Your interest means a lot to us, and we're excited to share our
           upcoming product with someone as enthusiastic as you.
         </span>
         <Link
           to="https://preggify.com/"
           className="bg-preggifyPurple rounded-full px-4 py-2  mt-8 text-white text-sm font-normal"
         >
           <button>Continue to website</button>
         </Link>
       </div>
       <img src={thanks} alt="thank you" className="h-[340px] mx-auto mt-2" />
     </div>
   );
};

export default Thankyou;
