import pgbg from "../../../Imgs/help.png";
const HelpAcademy = () => {
  return (
    <>
      <div>
        <div class="max-w-sm rounded overflow-hidden shadow-lg">
          <img class="w-full" src={pgbg} alt="Sunset in the mountains" />
          <div class="px-6 py-4">
            <div class="font-medium text-lg font-sand mb-2 text-preggifyPurple">
              Introduction
            </div>
            <p class="text-gray-700 text-sm font-light font-sand">
              {/* Lorem ipsum dolor sit amet, consectetur adipisicing elit. */}
            </p>
          </div>
          <div class="px-6 pt-2 pb-2">
            <span class="inline-block  bg-preggifyGreen rounded-full px-3 py-1 text-sm font-semibold text-white mr-2 mb-2 ">
              Coming Soon
            </span>
          </div>
        </div>
      </div>
      {/* <div>
        <div class="max-w-sm rounded overflow-hidden shadow-lg">
          <img class="w-full" src={pgbg} alt="Sunset in the mountains" />
          <div class="px-6 py-4">
            <div class="font-medium text-lg font-sand mb-2 text-preggifyPurple">
              Help 101
            </div>
            <p class="text-gray-700 text-sm font-light font-sand">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            </p>
          </div>
          <div class="px-6 pt-2 pb-2">
            <span class="inline-block  bg-preggifyGreen rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
              Coming Soon
            </span>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default HelpAcademy;
