import soon from "../../Imgs/soon.png";
const Soon = () => {
  return (
    <>
      <div className="relative">
        <img src={soon} alt="" className="absolute  lg:right-1/4 mt-4 " />
      </div>
    </>
  );
};

export default Soon;
