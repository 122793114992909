import { useSelector } from "react-redux";
import { test } from "../../reducers/user";
import { FaReadme, FaShare } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Link } from "react-router-dom";

const ResultViewer = () => {
  const testResult = useSelector(test);
  console.log(testResult);
  const depression_data = [];
  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  if (testResult?.length > 0) {
    // const testValue = depressionRsult.map((item) => {});
    for (var i = 0; i < testResult.length; i++) {
      var obj = testResult[i];
      var sum = 0;
      // Iterating over the properties of each object and calculating the sum
      for (var key in obj) {
        if (
          obj.hasOwnProperty(key) &&
          key !== "date_of_test" &&
          key !== "next_date" &&
          key !== "_id"
        ) {
          sum += obj[key];
        }
      }
      // Extracting the month name from the date property
      var date = new Date(obj.date_of_test);
      var testDate = moment(obj.date_of_test).format("MMM Do YYYY");
      var monthName = monthNames[date.getMonth()];

      // Creating a new object with the sum, highest, and month name
      var newObj = {
        DS: sum,
        DI: 20,
        name: monthName,
        formatedDate: testDate,
        key: obj._id,
      };

      // Adding the new object to the new array
      depression_data.push(newObj);
    }
    console.log("wawu", depression_data);
  }

  return (
    <>
      <div className=" px-5 mt-5 font-sand">
        <div className="">
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
            {depression_data.length > 0 ? (
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-white uppercase bg-preggifyPurple">
                  <tr>
                    <th scope="col" class="px-6 py-3">
                      Test Date
                    </th>

                    <th scope="col" class="px-6 py-3">
                      Mood
                    </th>
                    <th scope="col" class="px-6 py-3">
                      status
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Read suggestions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {depression_data.map((item) => {
                    return (
                      <tr
                        key={item.key}
                        class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                      >
                        <td class="px-6 py-4">{item.formatedDate} </td>
                        <td class="px-6 py-4">
                          <div class="flex items-center">
                            {/* <div class="h-2.5 w-2.5 rounded-full bg-green-500 mr-2"></div>{" "} */}
                            {item.DS < 10
                              ? "Mild"
                              : item.DS > 10 && item.DS < 13
                              ? "Moderate"
                              : "Severe"}
                          </div>
                        </td>
                        <td class="px-6 py-4">
                          {" "}
                          <div class="w-full  bg-gray-200 rounded-full h-2.5  dark:bg-gray-700">
                            <div
                              class={
                                item.DS < 10
                                  ? "bg-preggifyGreen h-2.5 rounded-full"
                                  : item.DS > 10 && item.DS < 13
                                  ? "bg-yellow-200 h-2.5 rounded-full"
                                  : "bg-red-600 h-2.5 rounded-full"
                              }
                              style={{ width: (item.DS / 20) * 100 + "%" }}
                            ></div>
                          </div>
                        </td>
                        <td class="px-6 py-4">
                          <Link
                            to="/userHome/suggestions"
                            state={{ data: item.DS }}
                          >
                            <FaReadme size={25} className="cursor-pointer" />
                          </Link>
                        </td>
                        {/* <td className="px-6 py-4">
                     
                     
                      </td> */}

                        {/* <td className="px-6 py-4">july 3rd 2023</td> */}
                      </tr>
                    );
                  })}

                  {/* <th
                    scope="row"
                    class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap"
                  >
              
                    <div class="pl-3">
                      <div class="text-base font-semibold">DR IFY</div>
                      <div class="text-sm font-normal text-black">
                        Available on preggify
                      </div>
                    </div>
                  </th> */}
                </tbody>
              </table>
            ) : (
              "No test result yet"
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ResultViewer;
