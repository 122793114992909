import { Navbar, Card, Button } from "flowbite-react";
import logo from "../Imgs/logo.png";

const NavBar = () => {
  return (
    <>
      <Navbar fluid={true} rounded={true} className=" border border-gray-200 font-sand ">
        <Navbar.Brand
        //     as={{
        //       $$typeof: Symbol(react.forward_ref),
        //       render: LinkWithRef,
        //     }}
        //     to="/navbars"
        >
          <img src={logo} className="mr-3 h-6 sm:h-9" alt="Flowbite Logo" />
          <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
            {/* Preggify */}
          </span>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Navbar.Link href="https://preggify.com/" className="font-sand">
            Home
          </Navbar.Link>
          {/* <Navbar.Link
            className="font-sand"

          >
            About
          </Navbar.Link> */}
          {/* <Navbar.Link href="/navbars">Services</Navbar.Link> */}
          {/* <Navbar.Link href="/navbars" active={true}>
            Pricing
          </Navbar.Link> */}
          <Navbar.Link href="/login">Login</Navbar.Link>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

export default NavBar
