import axios from "axios"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ToastContainer, toast } from "react-toastify";
import { Navigate } from "react-router-dom";


const URL = 'https://preggifyservices.preggify.com/preggify/v1/registration'
// const LOCAL = 'http://localhost:2500/preggify/v1/subscribe'

export const register = createAsyncThunk(
    "register/registerUser",
    async (args, { rejectWithValue }) => {
        try {
            const { data } = await axios.post(URL, args);
            toast.success(data.message)
            return data.message;
        } catch (err) {
            rejectWithValue(err.respose.data);
        }
    }
);

export const subuser = createAsyncThunk(
    "sub/subscribe",
    async (args, { rejectWithValue }) => {
        try {
            const { data } = await axios.post(URL, args);
            toast.success("Thanks for subscribing")
            console.log(data)
            return data
        } catch (err) {
            rejectWithValue(err.respose.data);
        }
    }
);
//Registration reducer
const registrtaionSlice = createSlice({
    name: "register_now",
    initialState: {
        isSuccess: false,
        message: "",
        loading: false,
        sub_type: "",
        plan: 0,
        incoming_val: ""

    },
    reducers: {
        Subscription: (state, action) => {
            console.log(action)
            state.sub_type = action.payload.sub
            state.incoming_val = action.payload.val
        },

    },
    //comments on this too.....
    extraReducers: (builder) => {
        // Handle the pending state
        builder.addCase(register.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        // Handle the fulfilled state
        builder.addCase(register.fulfilled, (state, action) => {
            state.loading = false;
            state.message = action.payload;
            state.isSuccess = true
        });
        // Handle the rejected state
        builder.addCase(register.rejected, (state, action) => {
            state.loading = false;
            // state.error = action.error.message;
        });

        builder.addCase(subuser.pending, (state, action) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(subuser.fulfilled, (state, action) => {
            const { message } = action.payload
            state.loading = false;
            state.message = message;
            state.isSuccess = true
        })
        builder.addCase(subuser.rejected, (state, action) => {
            state.loading = false;
            // state.error = action.error.message;
        })
    },

});

// export const val_to_pay = (state) => state.register.incoming_val
export const info = (state) => state.register.message
export const val_to_pay = (state) => state.register.incoming_val
export const loaderState = (state) => state.register.loading
export const subType = (state) => state.register.sub_type

export default registrtaionSlice.reducer
export const { Subscription, plan } = registrtaionSlice.actions