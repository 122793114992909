import nut from "../../Imgs/meal.jpg";
import prescription from "../../Imgs/icons/melatonin.png";
import excercise from "../../Imgs/fitness.jpg";
import vid from "../../Imgs/academypic.jpg";
import mood from "../../Imgs/pregmood.jpg";
import logger from "../../Imgs/icons/log.png";
import community from "../../Imgs/community.jpg";
import shopping from "../../Imgs/icons/shopping-cart.png";
import preggy from "../../Imgs/preggy1.png";
import food from "../../Imgs/food.png";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  affirmation,
  trimesterVal,
  contents,
  GANOW,
  setFree,
  check_sub,
  paymentRecords,
  subscriptionDetails,
} from "../../reducers/user";
import { useSelector } from "react-redux";
import tri1 from "../../Imgs/1st trimester.png";
import tri2 from "../../Imgs/2nd trimester.png";
import tri3 from "../../Imgs/3rd.png";
// import {  affirmation } from "../../reducers/user";
import { useEffect, useState } from "react";
const Services = () => {
  const dispatch = useDispatch();
  const paymentLogs_ = useSelector(paymentRecords);
  const [paymentLogs, setpaymentLog] = useState(paymentLogs_);
  const [showRandomFreeTips, setRandomFreeTips] = useState();
  const [showAffirmations, setAffirmations] = useState();
  const trimester = useSelector(trimesterVal);
  const content = useSelector(contents);
  const affirmations = useSelector(affirmation);
  const ga = useSelector(GANOW);

  const preggyTipsToShow = () => {
    if (ga > 0 && ga < 13) {
      return (
        <img
          src={tri1}
          alt=""
          className="mt-2 lg:h-[140px]sm:h-[70px] w-full"
        />
      );
    } else if (ga >= 13 && ga < 29) {
      return <img src={tri2} alt="" className="mt-2 w-[100px]" />;
    } else if (ga > 29) {
      return <img src={tri3} alt="" className="" />;
    }
  };

  // useEffect(() => {
  //   dispatch(subscriptionDetails());
  //   // dispatch(subscriptionDetails());
  //   var today = moment();
  //   const last_rec = paymentLogs[paymentLogs.length - 1];
  //   const next_renew = moment(last_rec?.next_due_payment);
  //   const diff_ = next_renew.diff(today, "days");
  //   // console.log("difference", diff_);
  //   if (paymentLogs.length !== 0 && diff_ == 0) {
  //     dispatch(setFree());
  //     dispatch(check_sub({ date: next_renew }));
  //   }
  // }, []);

  useEffect(() => {
    const randomContent = () => {
      var keys = Object.keys(content);
      var randomIndex = Math.floor(Math.random() * keys.length);
      var randomKey = keys[randomIndex];
      var randomValue = content[randomKey];
      setRandomFreeTips(randomValue);
    };
    randomContent();
    const interval = setInterval(randomContent, 1 * 60 * 1000);
    return () => {
      clearInterval(interval); // Clear the interval on component unmount
    };
  }, []);
  useEffect(() => {
    const randomContent = () => {
      var keys = Object.keys(affirmations);
      var randomIndex = Math.floor(Math.random() * keys.length);
      var randomKey = keys[randomIndex];
      var randomValue = affirmations[randomKey];
      setAffirmations(randomValue);
    };
    randomContent();
    const interval = setInterval(randomContent, 1 * 60 * 1000);
    return () => {
      clearInterval(interval); // Clear the interval on component unmount
    };
  }, []);
  return (
    <>
      <div className="mt-10 py-5">
        <div className="flex lg:flex-row sm:flex-col ml-4 mr-4 mb-10  gap-[30px] ">
          <div className=" bg-white px-5 py-5 rounded-lg shadow-[0_35px_60px_-15px_rgba(29, 33, 66, 0.1)] flex flex-col ">
            <hr className=" my-5 w-16 mx-4 border-b-4 border-[#fe2b7f] rounded-full" />
            <div className="flex flex-row-reverse  justify-between ">
              <div className="basis-[70%] px-5 font-sand ">
                <h1 className="font-bold lg:text-xl sm:text-base">
                  Pregnancy Tips for {trimester}
                </h1>
                <p className="mt-1 text-sm transition-all duration-300 ease-out">
                  Tips: {showRandomFreeTips}
                </p>
              </div>
              <div className="basis-[30%]">
                {/* <img src={preggy} className="lg:h-44" alt="" /> */}
                {preggyTipsToShow()}
              </div>
            </div>
          </div>
          <div className="basis-[50%] bg-white px-5 rounded-lg shadow-[0_35px_60px_-15px_rgba(29, 33, 66, 0.1)] flex flex-col ">
            <hr className=" my-5 w-16 mx-4 border-b-4 border-[#fea32b] rounded-full" />
            <div className="flex flex-row-reverse  justify-between">
              <div className="basis-[70%] px-5 font-sand">
                <h1 className="font-bold lg:text-xl sm:text-base ">
                  Affirmations for you
                </h1>
                <p className="mt-1 text-sm transition-all duration-300 ease-out">
                  {showAffirmations}
                </p>
              </div>
              <div className="basis-[30%]">
                <img src={food} className="mt-2 w-[100px]" alt="" />
              </div>
            </div>
          </div>
        </div>
        <h1 className="font-sand lg:text-xl sm:text-base font-bold ml-4 mr-4 mb-4">
          Services
        </h1>
        <div className="  lg:mt-[50px]   lg:flex-row items-center mb-10  sm:mt-[5px] justify-center  font-sand  bg-white rounded-md shadow-[0_35px_60px_-15px_rgba(29, 33, 66, 0.1)] flex  ml-4 mr-4 p-7">
          <div className="grid lg:grid-cols-5 sm:grid-cols-2 sm:gap-8">
            <div className="relative rounded-md overflow-hidden ">
              <Link to="/userHome/Videos" className="text-sm font-mt-2">
                <img src={vid} alt="" className="rounded-md" />
                <div className="absolute bottom-0 left-0 bg-black bg-opacity-50 w-full h-full py-2 px-4  text-center items-center flex justify-center hover:bg-opacity-70 transition-all duration-300 ease-in-out">
                  <h1 className="text-white lg:text-xl sm:text-base">
                    PREGNANCY ACADEMY
                  </h1>
                </div>
              </Link>
            </div>
            {/* <div className="basis-[25%]">
            <Link to="/userHome/Medication" className="text-sm font- mt-2">
              <img src={prescription} alt="" />
            </Link>
          </div> */}
            <div className="relative rounded-md overflow-hidden">
              <Link to="/userHome/Excercise" className="text-sm font- mt-2">
                <img src={excercise} alt="" className=" rounded-md" />
                <div className="absolute bottom-0 left-0 bg-black bg-opacity-50 w-full h-full py-2 px-4  text-center items-center flex justify-center hover:bg-opacity-70 transition-all duration-300 ease-in-out">
                  <h1 className="text-white lg:text-xl sm:text-base">
                    PREGNANCY FITNESS
                  </h1>
                </div>
              </Link>
            </div>

            <div className="relative rounded-md overflow-hidden">
              <Link to="/userHome/nutrition" className="text-sm font- mt-2">
                <img src={nut} className="rounded-md" alt="" />
                <div className="absolute bottom-0 left-0 bg-black bg-opacity-50 w-full h-full py-2 px-4  text-center items-center flex justify-center hover:bg-opacity-70 transition-all duration-300 ease-in-out">
                  <h1 className="text-white lg:text-xl sm:text-base">
                    FOOD DIARY
                  </h1>
                </div>
              </Link>
            </div>
            <div className="relative rounded-md overflow-hidden">
              <Link to="/userHome/MoodDetails">
                <img src={mood} className="rounded-md" alt="" />
                <div className="absolute bottom-0 left-0 bg-black bg-opacity-50 w-full h-full py-2 px-4  text-center items-center flex justify-center hover:bg-opacity-70 transition-all duration-300 ease-in-out">
                  <h1 className="text-white lg:text-xl sm:text-base">
                    MY MOOD
                  </h1>
                </div>
              </Link>

              {/* <h1 className="text-sm font- mt-2">Mood</h1> */}
            </div>
            <div className="relative rounded-md overflow-hidden">
              <Link to="/userHome/Communities">
                <img src={community} alt="" className="rounded-md" />
                <div className="absolute bottom-0 left-0 bg-black bg-opacity-50 w-full h-full py-2 px-4  text-center items-center flex justify-center hover:bg-opacity-70 transition-all duration-300 ease-in-out">
                  <h1 className="text-white lg:text-xl sm:text-base">
                    PREGGIFY COMMUNITY
                  </h1>
                </div>
              </Link>
              {/* <h1 className="text-sm font- mt-2">Community</h1> */}
            </div>
          </div>
        </div>

        {/* <div className="basis-[25%]">
          <img src={community} alt="" /> */}

        {/* <h1 className="text-sm font- mt-2">Community</h1> */}
        {/* </div> */}
        {/* <div className="basis-[25%]">
            <img src={shopping} alt="" />
            
          </div> 
        </div> */}
      </div>
    </>
  );
};

export default Services;
