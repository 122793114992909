import pgbg from "../../../Imgs/preggyy.jpg";
import { useState } from "react";
import { PaystackButton } from "react-paystack";
import { useDispatch, useSelector } from "react-redux";
import {
  pregnancy_vid,
  subType,
  firstName,
  lastName,
  email,
  phone,
} from "../../../reducers/user";

import { useNavigate } from "react-router-dom";
const PregnancyAcademy = () => {
  const vids = useSelector(pregnancy_vid);
  const userType = useSelector(subType);
  const [video, setVideo] = useState(vids);
  const navigate = useNavigate();
  const kie = process.env.REACT_APP_MY_STACK_KEY;
  const name = useSelector(firstName);
  // const acctype_ = useSelector(subType);
  const phoneNo = useSelector(phone);
  const mail = useSelector(email);
  const acctype_ = useSelector(subType);

  const componentProps = {
    className: "text-white font-sand font-normal",
    email: mail,
    amount: 500 * 100,
    metadata: {
      name: name,
      phone: phoneNo,
    },
    publicKey: kie,
    text: "Buy for N500",
    onSuccess: (reference) => {
      alert("Thanks for doing business with us! Come back soon!!", reference);
      // dispatch(
      //   update_subscription_status({
      //     amount: 5000,
      //     reference: reference.reference,
      //     status: reference.status,
      //   })
      // );
      // dispatch(setPremium());
      // dispatch(fetchProfile());
      // dispatch(subscriptionDetails());
    },

    onClose: () => alert("Wait! Don't leave :("),
  };

  return (
    <>
      {video?.map((x) => (
        <div class="max-w-sm rounded overflow-hidden shadow-lg" key={x._id}>
          <img class="w-full" src={pgbg} alt="Sunset in the mountains" />
          <div class="px-6 py-4">
            <div class="font-medium text-lg font-sand mb-2 text-preggifyPurple">
              {x.title}
            </div>
            <p class="text-gray-700 text-sm font-normal font-sand">
              {x.description.substring(0, 25) + "..."}
            </p>
          </div>
          <div class="px-6 pt-2 pb-2">
            {x.cost_for_non_users > 0 &&
            x.title !== "Introduction" &&
            userType !== "premium" ? (
              <span
                class="inline-block  bg-preggifyGreen rounded-full px-3 py-1 text-sm font-semibold font-sand text-white mr-2 mb-2 cursor-pointer"
                onClick={() => navigate("/userHome/settings", { state: 4 })}
              >
                Subscribe to watch
              </span>
            ) : (
              // <PaystackButton
              //   {...componentProps}
              //   className="inline-block  bg-preggifyGreen rounded-full px-3 py-1 text-sm font-semibold text-white mr-2 mb-2 cursor-pointer"
              // />
              <span
                class="inline-block  bg-preggifyGreen rounded-full px-3 py-1 font-sand text-sm font-semibold text-white mr-2 mb-2 cursor-pointer"
                onClick={() =>
                  navigate("/userHome/PlayScreen", { state: x.video_link })
                }
              >
                watch now
              </span>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default PregnancyAcademy;
