import { useLocation } from "react-router-dom";
import { firstName } from "../../reducers/user";
import { useSelector } from "react-redux";
const Suggestions = () => {
  const name = useSelector(firstName);
  const location = useLocation();
  const grade = location.state?.data;
  const message_to_display = () => {
    if (grade < 10) {
      return (
        <div className="font-sand mt-2 px-5">
          <p> Dear {name},I hope this meets you well.</p>
          <p>
            Your Mood score is {grade}. This is a good mental state and we hope
            that you keep up with your daily routines that improve your mood and
            how you feel.
          </p>
          <p>
            Remember to take it one day at a time ,while maintaining good
            relationship with your partner.
          </p>
          <p>
            If you need personal attention, kindly speak to your
            doctor at Preggify.
          </p>
        </div>
      );
    } else {
      return (
        <div className="font-sand mt-2 px-5">
          <p> Dear {name},I hope this meets you well.</p>
          <p>
            Your Mood score is {grade}. we understand that Pregnancy comes with
            different challenges and were here to support you.
          </p>
          <p>
            Your Mood score suggests that you're struggling with your daily
            routines and may need a few adjustments to improve your mood.
          </p>
          <p>
            Here are few things you can do to improve your mood and cope with
            your daily routines.
          </p>
          <br />
          <p>
            Talk to someone you trust. This could be a friend, family member,
            therapist, or other healthcare provider. Talking about how you're
            feeling can help you to feel less alone and can also help you to
            develop coping strategies. <br /> <br />
            Get regular exercise. Exercise releases endorphins, which have
            mood-boosting effects. Aim for at least 30 minutes of
            moderate-intensity exercise atleast three times daily. (Don't
            exercise ifbyiur doctor says otherwise). <br /> <br />
            Get enough sleep. When you're well-rested, you're better able to
            cope with stress and negative emotions. Aim for 7-8 hours of sleep
            per night. <br /> <br />
            Eat a healthy diet. Eating nutritious foods can help to improve your
            mood and energy levels. Make sure to include plenty of fruits,
            vegetables, and whole grains in your diet. See Meal diary for
            suggestions. <br /> <br /> Avoid alcohol and drugs. Alcohol and
            drugs can worsen low mood. If you're struggling with low mood, it's
            best to avoid these substances altogether. They are also harmful to
            your baby. <br /> <br /> Take care of yourself. Make sure to take
            some time for yourself each day to do something that you enjoy. This
            could be reading, taking a bath, or spending time in nature. <br />{" "}
            <br /> Be patient with yourself. It takes time to recover from low
            mood. Don't expect to feel better overnight. Be patient with
            yourself and focus on taking small steps each day.
          </p>
        </div>
      );
    }
  };
  return <>{message_to_display()}</>;
};

export default Suggestions;
