import logo from "../../Imgs/logo.png";
import { IoIosNotificationsOutline, IoIosMail } from "react-icons/io";
import { GiHamburgerMenu } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { setmenu, test } from "../../reducers/user";
import { Link } from "react-router-dom";

const DashboardView = () => {
  const testResult = useSelector(test);
  const dispatch = useDispatch();
  // console.log(testResult, "test");

  const toggleSideNav = () => {
    // console.log("shit");
    dispatch(setmenu());
  };
  return (
    <>
      <div className="flex items-center shadow-lg h-[80px]  justify-between px-[35px]  bg-[#f3f3f9] sm:w-[100%]">
        <div className="flex items-center">
          <div className="lg:hidden sm:inline-flex py-2">
            <GiHamburgerMenu
              onClick={toggleSideNav}
              size={"30px"}
              color="#1b1b1b"
            />
          </div>
          <div>
            <img src={logo} className=" h-14" alt="" />
          </div>
        </div>

        <div className="flex items-end gap-[15px] z-10 py-10  ">
          <div>
            <IoIosNotificationsOutline
              size={"30px"}
              color="#1b1b1b"
              className="z-10"
            />
          </div>
          <div>
            <Link to="/userHome/result">
              <IoIosMail
                size={"30px"}
                color={testResult?.length > 0 ? "#A216B3" : "#1b1b1b"}
                className="z-10 cursor-pointer"
              />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardView;
