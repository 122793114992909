import { Card, Carousel } from "flowbite-react";
import { useEffect, useState, createRef, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  logMeIn,
  logMeInfinalize,
  loaderB,
  loader,
  message,
  resendOTP,
  getOtpForReset,
  confirmOTPForReset,
  setNewPass,
} from "../reducers/login";
import { useNavigate } from "react-router-dom";
import { toks } from "../reducers/login";
import { fetchProfile } from "../reducers/user";
import UserLayout from "./Userdashboard/UserLayout";
import jwt_decode from "jwt-decode";
import logo from "../Imgs/logo.png";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
const ResetPassword = () => {
  const [resetAction, setResetAction] = useState(1);
  // const [email, setEmail] = useState();
  const [email, setEmail] = useState();
  const [password, setPass] = useState();
  const [confirmPass, setConfirmPass] = useState("");
  const nowMessage = useSelector(message);
  // const [listen, setListen] = useState(nowMessage);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginStatus = useSelector(toks);
  const loadingStatus = useSelector(loader);
  const loadingStatusB = useSelector(loaderB);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loginOrToken, setLoginOrToken] = useState(1);
  const [confirm, setConfirm] = useState("");
  const [inputValues, setInputValues] = useState(Array(6).fill(""));
  const inputRefs = Array.from({ length: 6 }, () => createRef());
  const handleInputChange = (index, event) => {
    // const newValue = event.target.value;
    const newValue = event.target.value;
    const newInputValues = [...inputValues];
    newInputValues[index] = newValue;
    setInputValues(newInputValues);
    if (newValue.length === 1 && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && !event.target.value && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };
  const confirmOtp = () => {
    const setData = inputValues.join("");
    dispatch(confirmOTPForReset({ otp: setData, email }));
  };

  useEffect(() => {
    // console.log(nowMessage);
    if (nowMessage === "Check your email for OTP") {
      setResetAction(2);
    } else if (nowMessage === "OK") {
      setResetAction(3);
    }
  }, [nowMessage]);

  const fetchOtpForReset = () => {
    dispatch(getOtpForReset({ email }));
  };
  const setNewPassNow = () => {
    if ((password == confirmPass) & (email !== "")) {
      dispatch(setNewPass({ email, new_pass: password }));
    } else {
      toast.warn("Password dose not match");
    }
  };

  const loginOrTokenCheck = () => {
    if (resetAction == 1) {
      return (
        <div className="flex  flex-wrap   items-center justify-center">
          <div>
            <img src={logo} className="lg:h-16 sm:h-16" alt="" />
            <Card className="shadow-none border-none w-full">
              <div className="flex flex-row ">
                <h1 className="flex font-sand font-bold text-2xl">
                  Reset password
                </h1>
              </div>
              <p className="font-normal font-sand text-sm text-gray-700 dark:text-gray-400">
                Enter your vaild email to reset your password
              </p>
              <form action="">
                <div className="mb-6">
                  <label
                    htmlFor="First name"
                    class="block mb-2 text-sm font-medium font-sand text-gray-90"
                  >
                    Email
                  </label>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    class=" border border-alternateGreen font-normal text-gray-900 text-sm rounded-lg font-sand focus:border-preggifyPurple block w-full p-2.5"
                    placeholder="user@preggify.com"
                    required
                  />
                </div>
              </form>
              <button
                onClick={fetchOtpForReset}
                className="rounded-full bg-alternateGreen p-2 text-white px-2 "
              >
                <span className="px-2 py-2 font-sand text-sm font-medium">
                  Request OTP
                </span>
                <svg
                  aria-hidden="true"
                  role="status"
                  className={
                    loadingStatus == true
                      ? "inline w-4 h-4 mr-3 text-white animate-spin"
                      : "inline w-0 h-0 mr-3 text-white "
                  }
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
              <div className="grid grid-cols-1 gap-2">
                <p className="font-sand text-sm text-gray-800">
                  Login
                  <span>
                    <Link className="text-black font-bold underline ml-1" to="/login">
                      click here
                    </Link>
                  </span>
                </p>
                <p className="font-sand text-sm text-gray-800">
                  Don't have an Account?
                  <span>
                    <Link to={"/Signup"} className="text-black font-bold underline ml-1">
                      SignUp
                    </Link>
                  </span>
                </p>
              </div>
            </Card>
          </div>
        </div>
      );
    } else if (resetAction == 2) {
      return (
        <div className="flex justify-center lg:shadow-sm  sm:px-2">
          <div className="justify-center items-center">
            <img src={logo} className="lg:h-16 sm:h-16" alt="" />
            <Card className="shadow-lg border-none w-full mt-10">
              <p className="font-normal font-sand text-sm text-gray-700 dark:text-gray-400">
                Enter your OTP to proceed
              </p>
              <div>
                {inputRefs.map((ref, index) => (
                  <input
                    key={index}
                    type="number"
                    className="lg:h-12 lg:w-12 sm:w-10 rounded-md bordder-2 border-alternateGreen lg:mx-1 sm:mx-[3px]"
                    maxLength="1"
                    ref={ref}
                    onChange={(event) => handleInputChange(index, event)}
                    onKeyDown={(event) => handleKeyDown(index, event)}
                  />
                ))}
              </div>

              <button
                onClick={confirmOtp}
                className="rounded-full bg-alternateGreen p-2 text-white px-2 "
              >
                <span className="px-2 py-2 font-sand text-sm font-medium">
                  Proceed
                </span>
                <svg
                  aria-hidden="true"
                  role="status"
                  className={
                    loadingStatusB == true
                      ? "inline w-4 h-4 mr-3 text-white animate-spin"
                      : "inline w-0 h-0 mr-3 text-white "
                  }
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </Card>
          </div>
        </div>
      );
    } else if (resetAction == 3) {
      return (
        <div className="flex  flex-wrap items-center justify-center">
          <div>
            <img src={logo} className="lg:h-16 sm:h-16" alt="" />
            <Card className="shadow-none border-none w-full">
              <div className="flex flex-row ">
                <h1 className="flex font-sand font-bold text-2xl">
                  Reset password
                </h1>
              </div>
              <p className="font-normal font-sand text-sm text-gray-700 dark:text-gray-400">
                Enter your vaild email to reset your password
              </p>
              <form action="">
                <div className="mb-6">
                  <label
                    htmlFor="First name"
                    class="block mb-2 text-sm font-medium font-sand text-gray-90"
                  >
                    New password
                  </label>
                  <input
                    onChange={(e) => setPass(e.target.value)}
                    type="password"
                    className=" border border-alternateGreen font-normal text-gray-900 text-sm rounded-lg font-sand focus:border-preggifyPurple block w-full p-2.5"
                    placeholder="user@preggify.com"
                    required
                  />
                </div>
                <div className="mb-6">
                  <label
                    htmlFor="First name"
                    class="block mb-2 text-sm font-medium font-sand text-gray-90"
                  >
                    retype new password
                  </label>
                  <input
                    onChange={(e) => setConfirmPass(e.target.value)}
                    type="password"
                    class=" border border-alternateGreen font-normal text-gray-900 text-sm rounded-lg font-sand focus:border-preggifyPurple block w-full p-2.5"
                    placeholder="user@preggify.com"
                    required
                  />
                </div>
              </form>
              <button
                onClick={setNewPassNow}
                className="rounded-full bg-alternateGreen p-2 text-white px-2 "
              >
                <span className="px-2 py-2 font-sand text-sm font-medium">
                  Set New Password
                </span>
                <svg
                  aria-hidden="true"
                  role="status"
                  className={
                    loadingStatus == true
                      ? "inline w-4 h-4 mr-3 text-white animate-spin"
                      : "inline w-0 h-0 mr-3 text-white "
                  }
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
              <div className="grid grid-cols-1 gap-2">
                <p className="font-sand text-sm text-gray-800">
                  Login
                  <span>
                    <Link className="text-black font-bold underline ml-1" to="/login">
                      click here
                    </Link>
                  </span>
                </p>
                <p className="font-sand text-sm text-gray-800">
                  Don't have an Account?
                  <span>
                    <Link to={"/Signup"} className="text-black font-bold underline ml-1">
                      SignUp
                    </Link>
                  </span>
                </p>
              </div>
            </Card>
          </div>
        </div>
      );
    }
  };
  return <>{loginOrTokenCheck()}</>;
};

export default ResetPassword;
