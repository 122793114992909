import axios from "axios"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ToastContainer, toast } from "react-toastify";

const BASEURL_LIVE = 'https://preggifyservices.preggify.com/preggify/v1/'
// const LOCAL = 'http://localhost:3000/preggify/v1/'
const URL = `${BASEURL_LIVE}login`
const URL_B = `${BASEURL_LIVE}login/finalize`
const URL_C = `${BASEURL_LIVE}login/resendotp`
const URL_D = `${BASEURL_LIVE}/resetpass`
const URL_E = `${BASEURL_LIVE}/confirmresetotp`
const URL_F = `${BASEURL_LIVE}/setnewpass`

export const logMeIn = createAsyncThunk(
      "login/loginUser",
      async (args, { rejectWithValue }) => {
            try {
                  const { data } = await axios.post(URL, args);
                  toast.info(data.message)
                  console.log(data)
                  return data;
            } catch (err) {
                  rejectWithValue(err.respose.data);
            }
      }
);
export const confirmOTPForReset = createAsyncThunk(
      "otpForReset/ConfirmOtpNow",
      async (args, { rejectWithValue }) => {
            try {
                  const { data } = await axios.post(URL_E, args);
                  toast.info(data.message)
                  // console.log(data)
                  return data;
            } catch (err) {
                  rejectWithValue(err.respose.data);
            }
      }
);
export const setNewPass = createAsyncThunk(
      "newPass/SetNewPass",
      async (args, { rejectWithValue }) => {
            try {
                  const { data } = await axios.put(URL_F, args);
                  toast.info(data.message)
                  // console.log(data)
                  return data;
            } catch (err) {
                  rejectWithValue(err.respose.data);
            }
      }
);

export const getOtpForReset = createAsyncThunk(
      "resetPass/getPasswordReset",
      async (args, { rejectWithValue }) => {
            try {
                  const { data } = await axios.post(URL_D, args);
                  toast.info(data.message)
                  // console.log(data)
                  return data;
            } catch (err) {
                  rejectWithValue(err.respose.data);
            }
      }
);
export const logMeInfinalize = createAsyncThunk(
      "login/finalize",
      async (args, { rejectWithValue }) => {
            try {
                  const { data } = await axios.post(URL_B, args);

                  // sessionStorage.setItem("tokken", data.auth_token)
                  toast.info(data.message)
                  return data;
            } catch (err) {
                  rejectWithValue(err.respose.data);
            }
      }
);
export const resendOTP = createAsyncThunk(
      "login/otpresend",
      async (args, { rejectWithValue }) => {
            try {
                  const { data } = await axios.post(URL_C, args);

                  // sessionStorage.setItem("tokken", data.auth_token)
                  toast.info(data.message)
                  return data;
            } catch (err) {
                  rejectWithValue(err.respose.data);
            }
      }
);

const loginSlice = createSlice({
      name: "login",
      initialState: {
            loading: false,
            success: false,
            token: "",
            loadingB: false,
            passMessage: "",

      },
      reducers: {},
      extraReducers: (builder) => {
            builder.addCase(logMeIn.pending, (state) => {
                  state.loading = true
            })
            builder.addCase(logMeIn.fulfilled, (state, action) => {
                  const { message, auth_token } = action.payload
                  state.success = true
                  state.loading = false
                  state.passMessage = message
                  state.token = auth_token
                  // state._id=id
            })
            builder.addCase(logMeIn.rejected, (state) => {
                  state.success = false
                  state.loading = false
            })
            builder.addCase(logMeInfinalize.pending, (state) => {
                  state.loadingB = true
            })
            builder.addCase(logMeInfinalize.fulfilled, (state, action) => {
                  const { message, auth_token } = action.payload
                  state.success = true
                  state.token = auth_token
                  state.loadingB = false
                  // state._id=id
            })
            builder.addCase(logMeInfinalize.rejected, (state) => {
                  state.success = false
                  state.loadingB = false
            })
            builder.addCase(resendOTP.pending, (state) => {
                  state.loadingB = true

            })
            builder.addCase(resendOTP.fulfilled, (state, action) => {
                  const { message } = action.payload
                  state.success = true
                  state.loadingB = false
                  // state.passMessage = message
            })
            builder.addCase(resendOTP.rejected, (state) => {
                  state.success = false
                  state.loadingB = false
            })
            builder.addCase(getOtpForReset.pending, (state) => {
                  state.loading = true

            })
            builder.addCase(getOtpForReset.fulfilled, (state, action) => {
                  const { message } = action.payload
                  state.success = true
                  state.loading = false
                  state.passMessage = message
            })
            builder.addCase(getOtpForReset.rejected, (state) => {
                  state.success = false
                  state.loading = false
            })
            builder.addCase(confirmOTPForReset.pending, (state) => {
                  state.loading = true

            })
            builder.addCase(confirmOTPForReset.fulfilled, (state, action) => {
                  const { message } = action.payload
                  state.success = true
                  state.loading = false
                  state.passMessage = message
            })
            builder.addCase(confirmOTPForReset.rejected, (state) => {
                  state.success = false
                  state.loading = false
            })
            builder.addCase(setNewPass.pending, (state) => {
                  state.loading = true

            })
            builder.addCase(setNewPass.fulfilled, (state, action) => {
                  const { message } = action.payload
                  state.success = true
                  state.loading = false
                  state.passMessage = message
            })
            builder.addCase(setNewPass.rejected, (state) => {
                  state.success = false
                  state.loading = false
            })
      }
})

export const toks = (state) => state.login.token
export const loader = (state) => state.login.loading
export const loaderB = (state) => state.login.loadingB
export const message = (state) => state.login.passMessage
export default loginSlice.reducer

// export const { Subscription, plan } = registrtaionSlice.actions