import { Card, Carousel } from "flowbite-react";
import { useEffect, useState, createRef, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  logMeIn,
  logMeInfinalize,
  loaderB,
  loader,
  message,
  resendOTP,
} from "../reducers/login";
import { useNavigate } from "react-router-dom";
import { toks } from "../reducers/login";
import { fetchProfile } from "../reducers/user";
import UserLayout from "./Userdashboard/UserLayout";
import jwt_decode from "jwt-decode";
import logo from "../Imgs/logo.png";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const Login = () => {
  const [email, setEmail] = useState();
  const [password, setPass] = useState();
  const nowMessage = useSelector(message);
  // const [listen, setListen] = useState(nowMessage);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginStatus = useSelector(toks);
  const loadingStatus = useSelector(loader);
  const loadingStatusB = useSelector(loaderB);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loginOrToken, setLoginOrToken] = useState(1);
  const [inputValues, setInputValues] = useState(Array(6).fill(""));
  const inputRefs = Array.from({ length: 6 }, () => createRef());

  // const inputRefs = Array.from({ length: 6 }, () => useRef(null));

  const handleInputChange = (index, event) => {
    // const newValue = event.target.value;
    const newValue = event.target.value;
    const newInputValues = [...inputValues];
    newInputValues[index] = newValue;
    setInputValues(newInputValues);
    if (newValue.length === 1 && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && !event.target.value && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };
  console.log("value", inputValues.join(""));

  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(logMeIn({ email, password }));
  };
  // console.log(nowMessage);
  useEffect(() => {
    if (nowMessage === "Login successful" && loginStatus) {
      sessionStorage.setItem("tokken", JSON.stringify(loginStatus));
      window.location.href = "/userHome/Dashboard";

    }
  }, [nowMessage, loginStatus]);


  const confirmOtp = () => {
    const setData = inputValues.join("");
    // const combine = box1.concat(box2, box3, box4, box5, box6);

    dispatch(logMeInfinalize({ otp: setData }));

    // toast.warning("OPT incomplete");
  };

  // useEffect(() => {
  //   if (loginStatus) {
  //     sessionStorage.setItem("tokken", JSON.stringify(loginStatus));
  //     window.location.href = "/userHome/Dashboard";
  //   }
  // }, [loginStatus]);

  const loginOrTokenCheck = () => {
    if (loginOrToken === 1) {
      return (
        <div className="flex  flex-wrap   items-center justify-center">
          <div>
            <img src={logo} className="lg:h-16 sm:h-16" alt="" />
            <Card className="shadow-none border-none w-full">
              <div className="flex flex-row ">
                <h1 className="flex font-sand font-bold text-2xl">Login</h1>
              </div>

              {/* <p className="font-sand text-sm mt-2 text-preggifyPurple">
                Login
              </p> */}

              <p className="font-normal font-sand text-sm text-gray-700 dark:text-gray-400">
                Enter your login details to continue your preggify journey
              </p>
              <form action="">
                <div className="mb-6">
                  <label
                    htmlFor="First name"
                    class="block mb-2 text-sm font-medium font-sand text-gray-90"
                  >
                    Email
                  </label>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    class=" border border-gray-700 font-normal text-gray-900 text-sm rounded-lg font-sand focus:border-preggifyPurple block w-full p-2.5"
                    placeholder="Email"
                    required
                  />
                </div>
                <div class="mb-6">
                  <label
                    htmlFor="First name"
                    class="block mb-2 text-sm font-medium font-sand text-gray-90"
                  >
                    Password
                  </label>
                  <input
                    onChange={(e) => setPass(e.target.value)}
                    type="password"
                    class=" border border-gray-700 font-normal text-gray-900 text-sm rounded-lg font-sand focus:border-preggifyPurple block w-full p-2.5"
                    placeholder="Password"
                    required
                  />
                </div>
              </form>
              <button
                onClick={handleLogin}
                className="rounded-full bg-alternateGreen p-2 text-white px-2 "
              >
                <span className="px-2 py-2 font-sand text-sm font-medium">
                  Login
                </span>
                <svg
                  aria-hidden="true"
                  role="status"
                  className={
                    loadingStatus == true
                      ? "inline w-4 h-4 mr-3 text-white animate-spin"
                      : "inline w-0 h-0 mr-3 text-white "
                  }
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
              <div className="grid grid-cols-1 gap-2">
                <p className="font-sand text-sm text-gray-800 ">
                  Forgot password?
                  <span>
                    <Link
                      className="text-black font-bold underline ml-1"
                      to="/resetpass"
                    >
                      Reset here
                    </Link>
                  </span>
                </p>
                <p className="font-sand text-sm text-gray-800">
                  Don't have an Account?
                  <span>
                    <Link
                      to={"/Signup"}
                      className="text-black font-bold underline ml-1"
                    >
                      SignUp
                    </Link>
                  </span>
                </p>
              </div>
            </Card>
          </div>
        </div>
      );
    } else if (loginOrToken == 2) {
      return (
        <div className="flex flex-wrap lg:basis-1/2 lg:shadow-sm  sm:px-2">
          <img src={logo} className="lg:h-16 sm:h-16" alt="" />
          <div>
            <Card className="shadow-lg border-none w-full mt-10">
              <div className="flex flex-row ">
                {/* <h1 className="flex font-sand font-medium text-xl">Login</h1> */}
              </div>

              {/* <p className="font-sand text-sm mt-2 text-preggifyPurple">
              Login
            </p> */}

              <p className="font-normal font-sand text-sm text-gray-700 dark:text-gray-400">
                Enter your OTP to proceed
              </p>
              <div>
                {inputRefs.map((ref, index) => (
                  <input
                    key={index}
                    type="number"
                    className="lg:h-12 lg:w-12 sm:w-10 rounded-md bordder-2 border-alternateGreen lg:mx-1 sm:mx-[3px]"
                    maxLength="1"
                    ref={ref}
                    onChange={(event) => handleInputChange(index, event)}
                    onKeyDown={(event) => handleKeyDown(index, event)}
                  />
                ))}
              </div>

              <button
                onClick={confirmOtp}
                className="rounded-full bg-alternateGreen p-2 text-white px-2 "
              >
                <span className="px-2 py-2 font-sand text-sm font-medium">
                  Proceed
                </span>
                <svg
                  aria-hidden="true"
                  role="status"
                  className={
                    loadingStatusB == true
                      ? "inline w-4 h-4 mr-3 text-white animate-spin"
                      : "inline w-0 h-0 mr-3 text-white "
                  }
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
              <div className="grid grid-cols-2">
                <p className="font-sand text-sm">
                  Resend OTP
                  <span>
                    <p
                      className="text-preggifyPurple cursor-pointer "
                      onClick={() => dispatch(resendOTP({ email }))}
                    >
                      click here
                    </p>
                  </span>
                </p>
                {/* <p className="font-sand text-sm">
                  Register
                  <span>
                    <Link to={"/Signup"} className="text-preggifyPurple">
                      {" "}
                      here
                    </Link>
                  </span>
                </p> */}
              </div>
            </Card>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div className="px-4 flex items-center justify-center h-[100dvh] bg-[#efffed]">
        <div className="flex  lg:flex-row sm:flex-col shadow-2xl shadow-[#47618735]  bg-white py-4 justify-between max-w-4xl px-4 rounded-lg">
          {/* Start of first 1/2 */}
          <div className="flex  flex-wrap">
            <div className="box-content max-w-md lg:inline-flex bg-gradient-to-br from-transparent to-[#053c0ed6]  bg-alternateGreen h-[90vh] w-[100%] sm:hidden  rounded-lg items-center justify-between">
              <div className="px-7">
                <h1 className="font-sand font-bold text-3xl text-white mt-5  ">
                  Welcome Back.
                </h1>
                <p className="font-sand text-sm font-normal mt-4 text-white">
                  {/* Discover the world of digital pregnancy care */}
                  provide your login details to continue accessing to
                  Professional pregnancy care at your fingertips with Preggify's
                  24/7 support!
                </p>
                <div className="lg:h-60 sm:h-10">
                  <Carousel
                    aria-controls={false}
                    leftControl={false}
                    rightControl={false}
                    slideInterval={6000}
                  >
                    <div className=" bg-[#07391d]  p-3 rounded-md h-30">
                      <h5 className="text-xl font-bold tracking-tight font-sand text-white">
                        Testimonial
                      </h5>
                      <p className="font-normal text-sm text-white font-sand">
                        -Dr Mojisola OGUNLAKIN- <br /> The doctors are always
                        ready to help and listen to you no matter your complaint
                        and would go all the way to ensure you get the help and
                        care you need no matter the time of the day. They would
                        follow you up with calls if needed.
                      </p>
                    </div>
                    <div className=" bg-[#07391d]  p-3 rounded-md h-30">
                      <h5 className="text-xl font-bold tracking-tight font-sand text-white">
                        Testimonial
                      </h5>
                      <p className="font-normal text-sm text-white font-sand">
                        -Preggify Dad: Mr Raphael- <br /> Thank you so much for
                        the care structure of preggify, for your care and
                        patience - for allowing me to disturb you even at odd
                        hours We're grateful Ma.
                      </p>
                    </div>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
          {/* End of first 1/2 */}
          {/* Start of first 1/2 */}
          {loginOrTokenCheck()}
        </div>
        {/* End of second 1/2 */}
      </div>
    </>
  );
};

export default Login;
