import ify from "../../../Imgs/ify.jpg";
import { useSelector, useDispatch } from "react-redux";
import {
  firstName,
  lastName,
  phone,
  email,
  loading,
  update_profile,
} from "../../../reducers/user";
import Select from "react-select";
import { countries, states } from "../../../countryData";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const name = useSelector(firstName);
  const lname = useSelector(lastName);
  const mail = useSelector(email);
  const phoneno = useSelector(phone);
  const [locationState, setLocationState] = useState();
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [weight, setWeight] = useState("");
  const [familyHistory, setFamilyHistory] = useState("");
  const [height, setHeight] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [genotype, setGenotype] = useState("");
  const [lga, setLGA] = useState("");
  const loadingStatus = useSelector(loading);

  const submitData = () => {
    // console.log("No shit");
    dispatch(
      update_profile({
        locationState,
        country,
        address,
        weight,
        familyHistory,
        height,
        bloodGroup,
        genotype,
        lga,
      })
    );

    navigate("/userHome/Dashboard", { state: "refresh" });
  };

  const showButton = () => {
    if (
      country == "NG" &&
      locationState !== "" &&
      height !== "" &&
      bloodGroup !== "" &&
      genotype !== "" &&
      familyHistory !== "" &&
      lga !== "" &&
      weight !== "" &&
      address !== "" &&
      locationState !== ""
    ) {
      return (
        <button
          onClick={submitData}
          className="rounded-full bg-preggifyPurple p-2 text-white px-2 "
        >
          <span className="px-2 py-2 font-sand text-sm font-medium">
            Update profile
          </span>
          <svg
            aria-hidden="true"
            role="status"
            className={
              loadingStatus == true
                ? "inline w-4 h-4 mr-3 text-white animate-spin"
                : "inline w-0 h-0 mr-3 text-white "
            }
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="#E5E7EB"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentColor"
            />
          </svg>
        </button>
      );
    } else if (
      country !== "NG" &&
      country !== "" &&
      height !== "" &&
      bloodGroup !== "" &&
      genotype !== "" &&
      familyHistory !== "" &&
      weight !== "" &&
      address !== ""
    ) {
      return (
        <button
          onClick={submitData}
          className="rounded-full mb-2 bg-preggifyPurple p-2 text-white px-2 "
        >
          <span className="px-2 py-2 font-sand text-sm font-medium">
            Update profile
          </span>
          <svg
            aria-hidden="true"
            role="status"
            className={
              loadingStatus == true
                ? "inline w-4 h-4 mr-3 text-white animate-spin"
                : "inline w-0 h-0 mr-3 text-white "
            }
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="#E5E7EB"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentColor"
            />
          </svg>
        </button>
      );
    } else {
      return (
        <p className="text-preggifyPurple">
          Kindly fill all the fields to submit
        </p>
      );
    }
  };

  var selectedStateData = "";
  var mapLGAs = [];
  if (locationState) {
    selectedStateData = states.find((x) => x.state === locationState);
    mapLGAs.push(selectedStateData);
  }
  const modifiedState = states.map((x) => {
    const newState = {};
    newState.value = x.state;
    newState.label = x.state;
    return newState;
  });

  const firstDefault = [
    {
      value: "--select--",
      label: "--select a state-- ",
    },
  ];

  const latestModify = firstDefault.concat(modifiedState);

  // console.log("LGAs list", selectedStateData);

  return (
    <>
      <div>
        <h1 className="font-sand font-medium text-sm">PROFILE INFORMATION</h1>

        <div className="font-sand mt-4   text-black ">
          <div className="h-24 w-24 justify-center flex items-center rounded-full bg-preggifyGreen my-3">
            {/* <img src={ify} className="h-14 w-14 rounded-full mb-4" alt="" /> */}
            <h1 className="font-sand text-[50px] font-semibold text-white">
              {name ? name[0] : ""}
            </h1>
            {/* <p className="font-medium">{name} </p> */}
          </div>
        </div>
        <div className="lg:columns-2 sm:columns-1 sm:mb-2 ">
          <div>
            <input
              disabled
              defaultValue={name}
              type="text"
              className="w-full rounded-md border-preggifyPurple bg-purple-100 sm:mb-3 lg:mb-0 text-sm"
            />
          </div>
          <div>
            <input
              disabled
              defaultValue={lname}
              type="text"
              className="w-full rounded-md bg-purple-100 border-preggifyPurple text-sm"
              placeholder="Last name"
            />
          </div>
        </div>
        <div className="lg:columns-2 sm:columns-1 sm:mb-2 mt-3">
          <div>
            <input
              disabled
              defaultValue={mail}
              type="email"
              className="w-full rounded-md bg-purple-100 border-preggifyPurple sm:mb-3 lg:mb-0 text-sm"
              placeholder="Email"
            />
          </div>
          <div>
            <input
              disabled
              defaultValue={phoneno}
              type="text"
              className="w-full rounded-md bg-purple-100 border-preggifyPurple text-sm"
              placeholder="Phone number"
            />
          </div>
        </div>
        <div>
          <Select
            placeholder="Select Country"
            onChange={(e) => setCountry(e.value)}
            className="border-preggifyPurple"
            options={countries}
          />
        </div>
        {/* <div>
          <Select className="border-preggifyPurple mt-2" options={countries} />
        </div> */}
        <div className="lg:columns-2 sm:columns-1 sm:mb-2 mt-3">
          <div>
            <input
              type="number"
              className="w-full rounded-md border-preggifyPurple sm:mb-3 lg:mb-0 text-sm"
              placeholder="Enter weight in KG"
              onChange={(e) => setWeight(e.target.value)}
            />
          </div>
          <div>
            <select
              onChange={(e) => setBloodGroup(e.target.value)}
              name=""
              className=" border border-preggifyPurple mt-2 text-gray-900 font-sand text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option>--Choose a Blood group--</option>

              <option value="A RhD positive (A+)">A RhD positive (A+)</option>
              <option value="A RhD negative (A-)">A RhD negative (A-)</option>
              <option value="B RhD positive (B+)">B RhD positive (B+)</option>
              <option value="B RhD negative (B-)">B RhD negative (B-)</option>
              <option value="O RhD positive (O+)">O RhD positive (O+)</option>
              <option value="O RhD negative (O-)">O RhD negative (O-)</option>
              <option value="AB RhD positive (AB+)">
                AB RhD positive (AB+)
              </option>
              <option value="AB RhD negative (AB-)">
                AB RhD negative (AB-)
              </option>
            </select>
          </div>
        </div>
        <div className="lg:columns-2 sm:columns-1 sm:mb-2 mt-3">
          <div>
            <select
              name="gender"
              onChange={(e) => setFamilyHistory(e.target.value)}
              className=" border border-preggifyPurple mt-0 text-gray-900 font-sand text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value="">--Select family history--</option>

              <option value="Hypertension or Diabetes from immediate family">
                Hypertension or Diabetes from immediate family
              </option>
              <option value="Down syndrome">Down syndrome</option>
              <option value="Spina bifida">Spina bifida</option>
              <option value="None">None</option>
              <option value="Others">Others</option>
            </select>
          </div>
          <div>
            <select
              onChange={(e) => setGenotype(e.target.value)}
              name="gender"
              className=" border border-preggifyPurple mt-2 text-gray-900 font-sand text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value="">---Choose your genotype--</option>

              <option value="AA">AA</option>
              <option value="AS">AS</option>
              <option value="Others">Others</option>
            </select>
          </div>
        </div>
        <div className="lg:columns-2 sm:columns-1 sm:mb-2 mt-3">
          <div>
            <input
              type="number"
              className="w-full rounded-md border-preggifyPurple sm:mb-3 lg:mb-0 text-sm"
              placeholder="Enter height in meters"
              onChange={(e) => setHeight(e.target.value)}
            />
          </div>
          <div>
            <input
              type="text"
              className="w-full rounded-md border-preggifyPurple sm:mb-3 lg:mb-0 text-sm"
              placeholder="Address"
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          {/* <div>
            <Select
            
            className="border-preggifyPurple" options={countries} />
          </div> */}
        </div>
        <div className="lg:columns-2 sm:columns-1 sm:mb-2 mt-3">
          <div className="mb-2">
            {country && country === "NG" ? (
              <select
                name="gender"
                onChange={(e) => setLocationState(e.target.value)}
                className=" border border-preggifyPurple mt-2 text-gray-900 font-sand text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                {latestModify.map((x) => (
                  <option key={x.value} value={x.value}>
                    {x.label}
                  </option>
                ))}
              </select>
            ) : (
              ""
            )}
          </div>
          <div className="mb-2">
            {locationState && selectedStateData !== "" ? (
              <select
                onChange={(e) => setLGA(e.target.value)}
                name="gender"
                className=" border border-preggifyPurple mt-2 text-gray-900 font-sand text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                {selectedStateData?.lgas.map((x) => (
                  <option value="x" key={x}>
                    {x}
                  </option>
                ))}
              </select>
            ) : (
              ""
            )}
          </div>
        </div>
        {showButton()}
      </div>
    </>
  );
};

export default Profile;
