import React from 'react'
import check from "../../Imgs/icons/check.svg";

const Feature = ({text}) => {
  return (
    <li className="flex items-center gap-2">
      <img src={check} alt="checkmark" />
      {text}
    </li>
  );
}

export default Feature