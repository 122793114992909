import videbg from "../../Imgs/Food.jpg";
import pgbg from "../../Imgs/preggyfood.jpg";
import carbs from "../../Imgs/carbs.jpg";
import cereal from "../../Imgs/cereal.jpg";
import fruits from "../../Imgs/fruits.jpg";
import veg from "../../Imgs/vegitable.jpg";
import tub from "../../Imgs/tuber.jpg";
import prot from "../../Imgs/protein.jpg";
import soup from "../../Imgs/soups.jpg";
import snacks from "../../Imgs/snacks.jpg";
import grains from "../../Imgs/grains.jpg";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { modalCheck, closeModal } from "../../reducers/user";

import { useSelector, useDispatch } from "react-redux";
const Food = () => {
  const openModal = useSelector(modalCheck);
  console.log("some things here", openModal);
  const dispatch = useDispatch();
  // const [modalIsOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    dispatch(closeModal(false));
  };
  return (
    <>
      <div className="mt-5 py-7 px-5 font-sand">
        <Modal
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-preggifyPurple text-white font-sand lg:text-sm rounded-md sm:text-[12px]"
          isOpen={openModal}
          // onAfterOpen={afterOpenModal}
          // onRequestClose={closeModal}
          // style={customStyles}
          // contentLabel="Example Modal"
        >
          <div className="lg:px-5 py-5 sm:px-2">
            <h2>Disclaimer Notice!</h2>
            <p>
              Disclaimer: The information provided in this nutrition guide is
              intended for general informational purposes only. It is not a
              substitute for medical advice, diagnosis or treatment. Kindly
              consult with your healthcare provider for personalised advice on
              food allergies and intolerance.
            </p>
            <button
              onClick={() => handleClose()}
              className="mt-5 rounded-[200px] h-7 w-20 bg-preggifyGreen"
            >
              I agree
            </button>
          </div>
        </Modal>
        <div className="  relative h-[250px] font-sand">
          <img src={videbg} className="h-[250px] w-full object-cover" alt="" />
          <div className="bg-[#140e1e] bg-opacity-90 absolute h-[250px] top-0 w-full p-4 flex justify-center rounded-md items-center">
            <div>
              <h3 className="text-center font-bold lg:text-[30px] sm:text-[15px] text-white">
                Create a balanced daily meal plan by choosing different types of
                foods that are suitable for each stage of pregnancy.
              </h3>
            </div>
          </div>
        </div>
        {/* Grid settings */}
        <div className="grid sm:gap-[15px] lg:gap-[30px] lg:grid-cols-4 sm:grid-cols-2 mt-10">
          <div>
            <div class="max-w-sm rounded overflow-hidden shadow-lg">
              <img class="w-full" src={carbs} alt="Sunset in the mountains" />
              <div class="lg:px-6 sm:px-2 py-4">
                <div class="font-medium text-lg font-sand mb-2 text-preggifyPurple">
                  Breakfast
                </div>
                <p class="text-gray-700 text-sm font-normal font-sand">
                  Breakfast suggestions
                </p>
              </div>
              <div class="px-6 pt-2 pb-2">
                <Link
                  to="/userHome/FoodDetails"
                  state={{ nut: "Breakfast" }}
                  className="inline-block  bg-preggifyGreen rounded-full px-3 py-1 text-sm font-semibold text-white mr-2 mb-2"
                >
                  View
                </Link>
              </div>
            </div>
          </div>
          {/* start of 2nd Grid */}
          <div>
            <div class="max-w-sm rounded overflow-hidden shadow-lg">
              <img class="w-full" src={cereal} alt="Sunset in the mountains" />
              <div class="lg:px-6 sm:px-2 py-4">
                <div class="font-medium text-lg font-sand mb-2 text-preggifyPurple">
                  Lunch
                </div>
                <p class="text-gray-700 text-sm font-normal font-sand">
                  Lunch suggestions
                </p>
              </div>
              <div class="px-6 pt-2 pb-2">
                <Link
                  to="/userHome/FoodDetails"
                  state={{ nut: "Lunch" }}
                  className="inline-block  bg-preggifyGreen rounded-full px-3 py-1 text-sm font-semibold text-white mr-2 mb-2"
                >
                  View
                </Link>
              </div>
            </div>
          </div>
          {/* Grid 03 */}
          <div className="03">
            <div class="max-w-sm rounded overflow-hidden shadow-lg">
              <img class="w-full" src={veg} alt="Sunset in the mountains" />
              <div class="lg:px-6 sm:px-2 py-4">
                <div class="font-medium text-lg font-sand mb-2 text-preggifyPurple">
                  Dinner
                </div>
                <p class="text-gray-700 text-sm font-normal font-sand">
                  Dinner suggestions
                </p>
              </div>
              <div class="px-6 pt-2 pb-2">
                <Link
                  to="/userHome/FoodDetails"
                  state={{ nut: "Dinner" }}
                  className="inline-block  bg-preggifyGreen rounded-full px-3 py-1 text-sm font-semibold text-white mr-2 mb-2"
                >
                  View
                </Link>
              </div>
            </div>
          </div>
          <div className="04">
            <div class="max-w-sm rounded overflow-hidden shadow-lg">
              <img class="w-full" src={snacks} alt="Sunset in the mountains" />
              <div class="lg:px-6 sm:px-2 py-4">
                <div class="font-medium text-lg font-sand mb-2 text-preggifyPurple">
                  Snacks/ fruits
                </div>
                <p class="text-gray-700 text-sm font-normal font-sand">
                  snacks suggestions
                </p>
              </div>
              <div class="px-6 pt-2 pb-2">
                <Link
                  to="/userHome/FoodDetails"
                  state={{ nut: "Snacks and beverages" }}
                  className="inline-block  bg-preggifyGreen rounded-full px-3 py-1 text-sm font-semibold text-white mr-2 mb-2"
                >
                  View
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Food;
