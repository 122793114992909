import { useEffect, useState } from "react";
import mood from "../../Imgs/mood.jpg";
import { loading, myID, setDepressiontest } from "../../reducers/user";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { test } from "../../reducers/user";
import { Link } from "react-router-dom";

const Mood = () => {
  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [PA, setPA] = useState("");
  const [DSOSTM, setDSOSTM] = useState("");
  const [LOIOPIAUTE, setLOIOPIAUTE] = useState("");
  const [FBAUOTUAF, setFBAUOTUAF] = useState("");
  const [FITOHLE, setFITOHLE] = useState("");
  const [DCSAWTORMD, setDCSAWTORMD] = useState("");
  const [TOHYOTTYWBOD, setTOHYOTTYWBOD] = useState("");
  const [FDDOH, setFDDOH] = useState("");
  const [BAROOMOSSSPDN, setBAROOMOSSSPDN] = useState("");
  const [IODIYW, setIODIYW] = useState("");
  const _loading = useSelector(loading);
  const [testDone, setTestDone] = useState("No");
  const [nextTestmonth, setNestTestMonth] = useState();
  const id = useSelector(myID);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const testval = useSelector(test);
  const nextDateToTakeTest = testval[testval.length - 1]?.next_date;
  const next = new Date(nextDateToTakeTest);
  const nextMonth = monthNames[next.getMonth()];
  const now = new Date();
  const nowMonth = monthNames[now.getMonth()];
  console.log(nowMonth, nextMonth);

  useEffect(() => {
    if (nextMonth) {
      setNestTestMonth(nextMonth);
      // nextTestMonth = nextMonth;
    } else {
      setNestTestMonth("lol");
    }
    // console.log(nextTestMonth);
  }, [testDone]);

  console.log(nextTestmonth, "kkk");
  const clearState = () => {
    setBAROOMOSSSPDN("");
    setDCSAWTORMD("");
    setDSOSTM("");
    setFBAUOTUAF("");
    setFDDOH("");
    setFITOHLE("");
    setIODIYW("");
    setLOIOPIAUTE("");
    setTOHYOTTYWBOD("");
    setPA("");
  };

  const submitMood = () => {
    dispatch(
      setDepressiontest({
        user_id: id,
        PA,
        DSOSTM,
        LOIOPIAUTE,
        FBAUOTUAF,
        FITOHLE,
        DCSAWTORMD,
        TOHYOTTYWBOD,
        FDDOH,
        BAROOMOSSSPDN,
        IODIYW,
      })
    );
    clearState();
    setTestDone("Yes");
  };

  const btnOrNot = () => {
    if (nextTestmonth !== "lol" || testDone == "Yes") {
      if (nowMonth !== nextMonth) {
        return (
          <>
            <p className="text-preggifyPurple font-bold">
              You have already taken test for {nowMonth}
              <span>
                <Link to={"/userHome/Dashboard"}> Click here!</Link>
              </span>
            </p>
          </>
        );
      }
    } else {
      if (
        PA !== "" &&
        DSOSTM !== "" &&
        DCSAWTORMD !== "" &&
        LOIOPIAUTE !== "" &&
        BAROOMOSSSPDN !== "" &&
        FBAUOTUAF !== "" &&
        FITOHLE !== "" &&
        IODIYW !== "" &&
        FDDOH !== "" &&
        TOHYOTTYWBOD !== ""
      ) {
        return (
          <>
            <button
              onClick={() => submitMood()}
              className="bg-preggifyPurple text-white h-[40px] w-[100px] rounded-[200px]"
            >
              <span className="px-2 py-2 font-sand text-sm font-medium">
                Submit
              </span>
              <svg
                aria-hidden="true"
                role="status"
                className={
                  _loading == true
                    ? "inline w-4 h-4 mr-3 text-white animate-spin"
                    : "inline w-0 h-0 mr-3 text-white "
                }
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          </>
        );
      } else {
        <p className="text-preggifyPurple font-bold">
          kindly fill all options to submit
        </p>;
      }
    }
  };

  return (
    <>
      <div className="px-5 py-2 font-sand text-sm ">
        <div>
          {/* <img src={mood} className="h-[250px] w-full object-cover " alt="" /> */}
          <div className="  relative h-[250px] font-sand">
            <img src={mood} className="h-[250px] w-full object-cover" alt="" />
            <div className="bg-[#140e1e] bg-opacity-90 absolute h-[250px] top-0 w-full p-4 flex justify-center rounded-md items-center">
              <div>
                    <h3 className="text-center font-bold lg:text-[30px] sm:text-[15px] text-white">
                Low Mood?😨 High mood?😊
              </h3>
              <p className="text-center text-white mt-2">
                Our monthly assessment helps us understand how you're doing and
                how we can best support you. <br /> After you submit, you'll
                receive feedback with your score and tips to help you
                have a better month
              </p>
              {/* <p className="text-center text-white">Categories:</p>
              <ul>
                <li className="text-center text-white">
                  Academy for Mum-to-be
                </li>
                <li className="text-center text-white">
                  Academy for Dad-to-be
                </li>
                <li className="text-center text-white">Academy for Helper</li>
              </ul> */}
              </div>
          
            </div>
          </div>
          <div className="font-normal py-2">
            <div className="px-5 bg-white py-2 shadow-sm">
              <p className="text-preggifyPurple font-semibold">
                1. Poor appetite or over-eating. It could be an increase or
                decrease in appetite
              </p>
              <p>select an option:</p>
              <div className="inline-block">
                <input
                  type="radio"
                  name="1"
                  value="0"
                  id="first"
                  className=""
                  onClick={(e) => setPA(e.target.value)}
                />
                <label htmlFor="first" className="px-2">
                  Not at all
                </label>
                <input
                  type="radio"
                  name="1"
                  id=""
                  value="1"
                  onClick={(e) => setPA(e.target.value)}
                />
                <label htmlFor="" className="px-2">
                  Several days
                </label>
                <input
                  type="radio"
                  name="1"
                  value="2"
                  id=""
                  onClick={(e) => setPA(e.target.value)}
                />
                <label htmlFor="" className="px-2">
                  Nearly every day
                </label>
              </div>
            </div>
          </div>
          <div className="font-normal py-1">
            <div className="px-5 bg-white py-2 shadow-sm">
              <p className="text-preggifyPurple font-semibold">
                2. Having difficulty falling asleep or staying asleep; or
                sleeping too much.
              </p>
              <p>select an option:</p>
              <div className="inline-block">
                <input
                  type="radio"
                  name="2"
                  value="0"
                  id="first"
                  className=""
                  onClick={(e) => setDSOSTM(e.target.value)}
                />
                <label htmlFor="first" className="px-2">
                  Not at all
                </label>
                <input
                  type="radio"
                  name="2"
                  id=""
                  value="1"
                  onClick={(e) => setDSOSTM(e.target.value)}
                />
                <label htmlFor="" className="px-2">
                  Several days
                </label>
                <input
                  type="radio"
                  name="2"
                  value="2"
                  id=""
                  onClick={(e) => setDSOSTM(e.target.value)}
                />
                <label htmlFor="" className="px-2">
                  Nearly every day
                </label>
              </div>
            </div>
          </div>
          <div className="font-normal py-1">
            <div className="px-5 bg-white py-2 shadow-sm">
              <p className="text-preggifyPurple font-semibold">
                3. Loss of interest or pleasure in activities you used to enjoy
              </p>
              <p>select an option:</p>
              <div className="inline-block">
                <input
                  type="radio"
                  name="3"
                  value="0"
                  id="first"
                  className=""
                  onClick={(e) => setLOIOPIAUTE(e.target.value)}
                />
                <label htmlFor="first" className="px-2">
                  Not at all
                </label>
                <input
                  type="radio"
                  name="3"
                  id=""
                  value="1"
                  onClick={(e) => setLOIOPIAUTE(e.target.value)}
                />
                <label htmlFor="" className="px-2">
                  Several days
                </label>
                <input
                  type="radio"
                  name="3"
                  value="2"
                  id=""
                  onClick={(e) => setLOIOPIAUTE(e.target.value)}
                />
                <label htmlFor="" className="px-2">
                  Nearly every day
                </label>
              </div>
            </div>
          </div>
          <div className="font-normal py-1">
            <div className="px-5 bg-white py-2 shadow-sm">
              <p className="text-preggifyPurple font-semibold">
                4. Feeling bad about yourself or that you are a failure and have
                let people around you down.
              </p>
              <p>select an option:</p>
              <div className="inline-block">
                <input
                  type="radio"
                  name="4"
                  value="0"
                  id="first"
                  className=""
                  onClick={(e) => setFBAUOTUAF(e.target.value)}
                />
                <label htmlFor="first" className="px-2">
                  Not at all
                </label>
                <input
                  type="radio"
                  name="4"
                  id=""
                  value="1"
                  onClick={(e) => setFBAUOTUAF(e.target.value)}
                />
                <label htmlFor="" className="px-2">
                  Several days
                </label>
                <input
                  type="radio"
                  name="4"
                  value="2"
                  id=""
                  onClick={(e) => setFBAUOTUAF(e.target.value)}
                />
                <label htmlFor="" className="px-2">
                  Nearly every day
                </label>
              </div>
            </div>
          </div>
          <div className="font-normal py-1">
            <div className="px-5 bg-white py-2 shadow-sm">
              <p className="text-preggifyPurple font-semibold">
                5. Feeling tired or having low energy
              </p>
              <p>select an option:</p>
              <div className="inline-block">
                <input
                  type="radio"
                  name="5"
                  value="0"
                  id="first"
                  className=""
                  onClick={(e) => setFITOHLE(e.target.value)}
                />
                <label htmlFor="first" className="px-2">
                  Not at all
                </label>
                <input
                  type="radio"
                  name="5"
                  id=""
                  value="1"
                  onClick={(e) => setFITOHLE(e.target.value)}
                />
                <label htmlFor="" className="px-2">
                  Several days
                </label>
                <input
                  type="radio"
                  name="5"
                  value="2"
                  id=""
                  onClick={(e) => setFITOHLE(e.target.value)}
                />
                <label htmlFor="" className="px-2">
                  Nearly every day
                </label>
              </div>
            </div>
          </div>
          <div className="font-normal py-1">
            <div className="px-5 bg-white py-2 shadow-sm">
              <p className="text-preggifyPurple font-semibold">
                6. Difficulty concentrating such as watching televisions or
                reading or making decisions
              </p>
              <p>select an option:</p>
              <div className="inline-block">
                <input
                  type="radio"
                  name="6"
                  value="0"
                  id="first"
                  className=""
                  onClick={(e) => setDCSAWTORMD(e.target.value)}
                />
                <label htmlFor="first" className="px-2">
                  Not at all
                </label>
                <input
                  type="radio"
                  name="6"
                  id=""
                  value="1"
                  onClick={(e) => setDCSAWTORMD(e.target.value)}
                />
                <label htmlFor="" className="px-2">
                  Several days
                </label>
                <input
                  type="radio"
                  name="6"
                  value="2"
                  id=""
                  onClick={(e) => setDCSAWTORMD(e.target.value)}
                />
                <label htmlFor="" className="px-2">
                  Nearly every day
                </label>
              </div>
            </div>
          </div>
          <div className="font-normal py-1">
            <div className="px-5 bg-white py-2 shadow-sm">
              <p className="text-preggifyPurple font-semibold">
                7. Thoughts of harming yourself or thinking that you will be
                better off dead
              </p>
              <p>select an option:</p>
              <div className="inline-block">
                <input
                  type="radio"
                  name="7"
                  value="0"
                  id="first"
                  className=""
                  onClick={(e) => setTOHYOTTYWBOD(e.target.value)}
                />
                <label htmlFor="first" className="px-2">
                  Not at all
                </label>
                <input
                  type="radio"
                  name="7"
                  id=""
                  value="1"
                  onClick={(e) => setTOHYOTTYWBOD(e.target.value)}
                />
                <label htmlFor="" className="px-2">
                  Several days
                </label>
                <input
                  type="radio"
                  name="7"
                  value="2"
                  id=""
                  onClick={(e) => setTOHYOTTYWBOD(e.target.value)}
                />
                <label htmlFor="" className="px-2">
                  Nearly every day
                </label>
              </div>
            </div>
          </div>
          <div className="font-normal py-1">
            <div className="px-5 bg-white py-2 shadow-sm">
              <p className="text-preggifyPurple font-semibold">
                8. Feeling down, depressed or hopeless
              </p>
              <p>select an option:</p>
              <div className="inline-block">
                <input
                  type="radio"
                  name="8"
                  value="0"
                  id="first"
                  className=""
                  onClick={(e) => setFDDOH(e.target.value)}
                />
                <label htmlFor="first" className="px-2">
                  Not at all
                </label>
                <input
                  type="radio"
                  name="8"
                  id=""
                  value="1"
                  onClick={(e) => setFDDOH(e.target.value)}
                />
                <label htmlFor="" className="px-2">
                  Several days
                </label>
                <input
                  type="radio"
                  name="8"
                  value="2"
                  id=""
                  onClick={(e) => setFDDOH(e.target.value)}
                />
                <label htmlFor="" className="px-2">
                  Nearly every day
                </label>
              </div>
            </div>
          </div>
          <div className="font-normal py-1">
            <div className="px-5 bg-white py-2 shadow-sm">
              <p className="text-preggifyPurple font-semibold">
                9. Being anxious,restless or the opposite - moving or speaking
                slowly so people don’t notice
              </p>
              <p>select an option:</p>
              <div className="inline-block">
                <input
                  type="radio"
                  name="9"
                  value="0"
                  id="first"
                  className=""
                  onClick={(e) => setBAROOMOSSSPDN(e.target.value)}
                />
                <label htmlFor="first" className="px-2">
                  Not at all
                </label>
                <input
                  type="radio"
                  name="9"
                  id=""
                  value="1"
                  onClick={(e) => setBAROOMOSSSPDN(e.target.value)}
                />
                <label htmlFor="" className="px-2">
                  Several days
                </label>
                <input
                  type="radio"
                  name="9"
                  value="2"
                  id=""
                  onClick={(e) => setBAROOMOSSSPDN(e.target.value)}
                />
                <label htmlFor="" className="px-2">
                  Nearly every day
                </label>
              </div>
            </div>
          </div>
          <div className="font-normal py-1">
            <div className="px-5 bg-white py-2 shadow-sm">
              <p className="text-preggifyPurple font-semibold">
                10. Increase or decrease in your weight
              </p>
              <p>select an option:</p>
              <div className="inline-block">
                <input
                  type="radio"
                  name="10"
                  value="0"
                  id="first"
                  className=""
                  onClick={(e) => setIODIYW(e.target.value)}
                />
                <label htmlFor="first" className="px-2">
                  Not at all
                </label>
                <input
                  type="radio"
                  name="10"
                  id=""
                  value="1"
                  onClick={(e) => setIODIYW(e.target.value)}
                />
                <label htmlFor="" className="px-2">
                  Several days
                </label>
                <input
                  type="radio"
                  name="10"
                  value="2"
                  id=""
                  onClick={(e) => setIODIYW(e.target.value)}
                />
                <label htmlFor="" className="px-2">
                  Nearly every day
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="py-2">
          {/* {PA !== "" &&
          DSOSTM !== "" &&
          DCSAWTORMD !== "" &&
          LOIOPIAUTE !== "" &&
          BAROOMOSSSPDN !== "" &&
          FBAUOTUAF !== "" &&
          FITOHLE !== "" &&
          IODIYW !== "" &&
          FDDOH !== "" &&
          TOHYOTTYWBOD !== "" ? (
            <button
              onClick={() => submitMood()}
              className="bg-preggifyPurple text-white h-[40px] w-[100px] rounded-[200px]"
            >
              <span className="px-2 py-2 font-sand text-sm font-medium">
                Submit
              </span>
              <svg
                aria-hidden="true"
                role="status"
                className={
                  _loading == true
                    ? "inline w-4 h-4 mr-3 text-white animate-spin"
                    : "inline w-0 h-0 mr-3 text-white "
                }
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          ) : (
            <p className="text-preggifyPurple font-bold">
              kindly fill all options to submit
            </p>
          )} */}
          {btnOrNot()}
        </div>
      </div>
    </>
  );
};

export default Mood;
