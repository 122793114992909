const MyVideos = () => {
  return (
    <>
      <div className="">
        <p
          className="font-sand
       text-sm font-normal"
        >
          You havent purchased any video
        </p>
      </div>
    </>
  );
};

export default MyVideos;
