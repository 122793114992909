import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Subscription, plan } from "../reducers/registration";
import Feature from "./reusables/features";
import NavBar from "./Navbar";
// import { toggleButtonClasses } from "@mui/material";

const Nav = () => {
  const dispatch = useDispatch();
  const [servicePrice, setServicePrice] = useState({
    monthly: 5000,
    pay: 35000,
    slashed: 50000,
  });
  const formatter = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  });

  const [selectedPlan, setSelectedPlan] = useState(true);
  const [initial_val, set_initial_val] = useState("");
  // console.log("Welcome", initial_val);
  useEffect(() => {
    set_initial_val(50000);
  }, []);

  // Payment plan toggle...
  // const togglePaymentPlan = () => {
  //   if (selectedPlan === true) {
  //     setSelectedPlan(false);
  //     // dispatch()
  //   } else {
  //     setSelectedPlan(true);
  //   }
  // };

  // useEffect(() => {
  //   if (selectedPlan === true) {
  //     // console.log("pay this", servicePrice.monthly);
  //     set_initial_val(servicePrice.monthly);
  //   } else {
  //     console.log("pay this", servicePrice.pay);
  //     set_initial_val(servicePrice.pay);
  //   }
  // }, [selectedPlan, servicePrice.monthly, servicePrice.pay]);

  //set payment plan
  const displayPrice = () => {
    return (
      <h1 className="text-left font-bold font-sand text-3xl">
        {formatter.format(servicePrice.slashed)}
      </h1>
    );
  };
  return (
    <>
      <NavBar />
      <div className="py-10 text-center sm:px-5 lg:px-0 flex flex-col lg:gap-8 gap-4 bg-[#fef8ff] overflow-hidden">
        <h1 className="font-sand text-3xl font-bold text-gray-900">
          Choose your right plan!
        </h1>
        <span className="text-sm md:text-base w-[90%] md:w-[60%] m-auto opacity-50 font-sand">
          Join the ultimate pregnancy companion – Preggify: your all-in-one
          platform for conceiving and nurturing a healthy pregnancy journey.
        </span>

        <div className="mt-5 flex justify-center">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* First col */}
            <div className="flex flex-col justify-between h-[620px] w-[350px] lg:basis-1/3 p-8 rounded-lg  bg-white border-gray-300 border cursor-pointer">
              <div className="flex flex-col gap-4">
                <span className="text-left font-sand text-xl px-4 py-1 rounded-lg w-[fit-content] text-white font-semibold bg-preggifyPurple">
                  Free
                </span>
                {/* <h2 className="text-left font-sand mt-2 text-preggifyPurple"></h2> */}
                <p className="font-sand font-normal text-sm text-left">
                  Begin your journey to safe and joyful motherhood with us.
                </p>

                <h1 className="text-left font-medium text-3xl">&#8358;0.00</h1>
                <p className="font-sand font-normal text-sm text-left">
                  1 User/account
                </p>
                <hr className="w-full sm:hidden lg:block bg-grey-300 h-0.5" />
                <ul className="max-w-md space-y-1 font-sand font-normal text-sm list-inside ">
                  <Feature text="Pregnancy Tracker" />
                  <Feature text="Food diary" />
                  <Feature text="Mood assesment" />
                  <Feature text="Preggify community" />
                  {/* <Feature text="Depression detector" /> */}
                </ul>
              </div>

              <Link
                to="/signup"
                onClick={() =>
                  dispatch(Subscription({ sub: "Free", val: initial_val }))
                }
              >
                <div className="rounded-lg text-preggifyPurple w-full block p-2 border border-gray-300 hover:bg-preggifyPurple transition-colors duration-300 ease-in-out hover:text-white">
                  <span className="font-sand text-sm font-bold">
                    Get Started
                  </span>
                </div>
              </Link>
            </div>
            {/* End of first col */}

            {/* Start of second col */}
            <div className="flex flex-col gap-8 lg:h-full  w-[350px] lg:basis-1/3 p-8 rounded-lg border-gray-200 border bg-gradient">
              <div className="flex flex-col gap-4">
                <div className="flex items-center gap-2">
                  <span className="text-left font-sand text-xl px-4 py-1 rounded-lg w-[fit-content] text-white font-semibold bg-preggifyPurple">
                    Premium
                  </span>
                  <div
                    // onClick={togglePaymentPlan}
                    class="flex bg-[#D4A1C9] rounded-lg p-[3px] text-[11px] font-bold font-sand text-gray-900 dark:text-gray-300"
                  >
                    <button
                      className="py-[2px] px-4 
                         bg-white rounded-lg"
                    >
                      Per Pregnancy
                    </button>
                  </div>
                </div>
                <p className="font-sand font-normal text-sm text-left">
                  Begin your journey to safe and joyful motherhood with us.
                </p>
                {displayPrice()}

                <p className="font-sand font-normal text-sm text-left">
                  This plan enables you add your partner and one other person
                </p>
                <hr className="w-full sm:hidden lg:block bg-grey-300 h-0.5" />
                <ul className="max-w-md space-y-1 font-sand font-normal text-sm list-inside ">
                  <Feature text="Pregnancy Tracker" />
                  <Feature text="Weekly classes" />
                  <Feature text="Personal doctor" />
                  <Feature text="Hospital list" />
                  <Feature text="Food diary" />
                  <Feature text="Pregnancy fitness" />
                  <Feature text="Pregnancy academy" />
                  <Feature text="Premium community" />
                  <Feature text="Nutrition" />
                  <Feature text="Mood assessment" />
                  {/* <Feature text="Preggify community" />
                  <Feature text="Pregnancy Journal" /> */}
                </ul>
              </div>

              <Link
                onClick={() =>
                  dispatch(Subscription({ sub: "premium", val: initial_val }))
                }
                to="/signup"
              >
                <div className="rounded-lg bg-preggifyPurple text-white w-full block p-2 hover:shadow-lg hover:border-0 border-grey-300 border-[1px]">
                  <span className="font-sand text-sm font-bold">
                    Register Now
                  </span>{" "}
                </div>
              </Link>
            </div>
            {/* End of second col */}

            {/* Start of third col */}
            <div className="flex flex-col justify-between  h-[620px]  w-[350px] lg:basis-1/3 p-8 rounded-lg bg-white border-gray-300 border ">
              <div className="flex flex-col gap-4">
                <span className="text-left font-sand text-xl px-4 py-1 rounded-lg w-[fit-content] text-white font-semibold bg-preggifyPurple">
                  Not Pregnant?
                </span>
                <p className="font-sand font-normal text-sm text-left">
                  Use our ovulation tracker
                </p>
                <p></p>
                <h1 className="text-left font-medium text-3xl">FREE</h1>
                <p></p>
                <hr className="w-full sm:hidden lg:block bg-grey-300 h-0.5" />
                <ul className="max-w-md space-y-1 font-sand font-normal text-sm list-inside ">
                  <Feature text="Ovulation Tracker" />
                  <Feature text="Menstrual Calendar" />
                  <Feature text="Preconception Tips" />
                  <Feature text="Daily Affirmation" />
                </ul>
              </div>

              <Link to="/waitlist">
                <div className="rounded-lg text-preggifyPurple w-full block p-2 border border-gray-300 cursor-pointer hover:bg-preggifyPurple transition-colors duration-300 ease-in-out hover:text-white">
                  <span className="font-sand text-sm font-bold">
                    Get Started
                  </span>{" "}
                  {/* <Link
                  to="/signup"
                  // onClick={() =>
                  //   dispatch(Subscription({ sub: "Not_Pregnant", val: initial_val }))
                  // }
                >
                  <span className="font-sand text-sm font-bold">
                    Get Started
                  </span>
                </Link> */}
                </div>
              </Link>
            </div>
            {/* End of third col */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Nav;
