//Gestational age

export const calculateGestationalAge = (lmpDate) => {
      const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
      const currentDate = new Date();

      // Calculate the difference in days between the current date and the LMP date
      const diffDays = Math.round(Math.abs((currentDate - lmpDate) / oneDay));

      // Calculate the gestational age in weeks
      const gestationalAgeWeeks = Math.floor(diffDays / 7);
      const gestationalAgeDays = diffDays % 7;
      return gestationalAgeWeeks;
};

// const lmpDate = new Date(lmp); // Example date of the last menstrual period

//EDD cals
export const calculateExpectedDeliveryDate = (lmpDate) => {
      const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
      // Calculate the expected delivery date by adding 280 days (40 weeks) to the LMP date
      const expectedDeliveryDate = new Date(lmpDate.getTime() + 280 * oneDay);

      return expectedDeliveryDate.toDateString();
};

//Generate random content