import pgbg from "../../../Imgs/pregnancyIntro.png";
import firstTri from "../../../Imgs/firstTri.png";
import SecondTri from "../../../Imgs/SecondTri.png";
import ThirdTri from "../../../Imgs/ThirdTri.png";
import cravings from "../../../Imgs/cravings.png";
import sex from "../../../Imgs/sex.png";
import smoking from "../../../Imgs/smoking.png";
import deliever from "../../../Imgs/deliever.png";
import labor from "../../../Imgs/labor.png";
import { pregnancy_vid, subType } from "../../../reducers/user";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { PaystackButton } from "react-paystack";
import { Button, Modal } from "flowbite-react";
import { useState } from "react";
const kie = process.env.REACT_APP_MY_STACK_KEY;
const amount = 1000;
// console.log("key shit", kie);

// import PaystackPop from '@paystack/inline-js';

const PartnerAcademy = () => {
  const sub = useSelector(subType);
  const vids = useSelector(pregnancy_vid);
  const [openModal, setOpenModal] = useState();
  const [videos, setVideos] = useState(vids);
  const props = { openModal, setOpenModal };
  console.log("sub shit", sub);

  // const componentProps = {
  //   className: "text-white font-sand font-normal",
  //   email: "temi@gmail.com",
  //   amount: amount,
  //   metadata: {
  //     name: "Temitayo",
  //     phone: "09099999999",
  //   },
  //   publicKey: kie,
  //   text: "Pay now",
  //   onSuccess: (reference) => {
  //     alert("Thanks for doing business with us! Come back soon!!", reference);
  //   },

  //   onClose: () => alert("Wait! Don't leave :("),
  // };

  return (
    <>
      <div className="">
        <div class="max-w-sm rounded overflow-hidden shadow-lg ">
          <img class="w-full" src={cravings} alt="Sunset in the mountains" />
          <div class="px-6 py-4">
            <div class="font-medium text-lg font-sand mb-2 text-preggifyPurple">
              Introduction to pregnancy
            </div>
            <p class="text-gray-700 text-sm font-normal font-sand">
              Everything you should know about pregnancy as a male Partner
            </p>
          </div>
          <div class="px-6 pt-2 pb-2 ">
            <span class="inline-block  bg-preggifyGreen rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 font-sand">
              <Link to="" className="text-white" state={{ myData: "Shit" }}>
                Coming Soon
              </Link>
            </span>
          </div>
        </div>
      </div>
      <div>
        <div class="max-w-sm rounded overflow-hidden shadow-lg">
          <img class="w-full" src={cravings} alt="Sunset in the mountains" />
          <div class="px-6 py-4">
            <div class="font-medium text-lg font-sand mb-2 text-preggifyPurple">
              First trimester
            </div>
            <p class="text-gray-700 text-sm font-normal font-sand">
              Learn about the new changes and coping mechanisms
            </p>
          </div>
          <div class="px-6 pt-2 pb-2">
            <span class="inline-block  bg-preggifyGreen rounded-full px-3 py-1 text-sm font-semibold font-sand text-gray-700 mr-2 mb-2">
              <p className="text-white">Coming Soon</p>
            </span>
          </div>
        </div>
      </div>
      <div>
        <div class="max-w-sm rounded overflow-hidden shadow-lg">
          <img class="w-full" src={cravings} alt="Sunset in the mountains" />
          <div class="px-6 py-4">
            <div class="font-medium text-lg font-sand mb-2 text-preggifyPurple">
              Second trimester
            </div>
            <p class="text-gray-700 text-sm font-normal font-sand">
              understand what matters the most at this phase .
            </p>
          </div>
          <div class="px-6 pt-2 pb-2">
            <span class="inline-block  bg-preggifyGreen rounded-full px-3 py-1 text-sm font-semibold font-sand text-gray-700 mr-2 mb-2">
              <p className="text-white">Coming Soon</p>
            </span>
          </div>
        </div>
      </div>
      <div>
        <div class="max-w-sm rounded overflow-hidden shadow-lg">
          <img class="w-full" src={cravings} alt="Sunset in the mountains" />
          <div class="px-6 py-4">
            <div class="font-medium text-lg font-sand mb-2 text-preggifyPurple">
              Third trimester
            </div>
            <p class="text-gray-700 text-sm font-normal font-sand">
              Learn what the final phase is and how to support your partner
            </p>
          </div>
          <div class="px-6 pt-2 pb-2">
            <span class="inline-block  bg-preggifyGreen rounded-full px-3 py-1 text-sm font-semibold font-sand text-gray-700 mr-2 mb-2">
              <p className="text-white">Coming Soon</p>
            </span>
          </div>
        </div>
      </div>
      <div>
        <div class="max-w-sm rounded overflow-hidden shadow-lg">
          <img class="w-full" src={cravings} alt="Sunset in the mountains" />
          <div class="px-6 py-4">
            <div class="font-medium text-lg font-sand mb-2 text-preggifyPurple">
              Pregnancy cravings
            </div>
            <p class="text-gray-700 text-sm font-normal font-sand">
              Learn the healthy and unhealthy cravings in pregnancy
            </p>
          </div>
          <div class="px-6 pt-2 pb-2">
            <span class="inline-block  bg-preggifyGreen rounded-full px-3 py-1 text-sm font-semibold font-sand text-gray-700 mr-2 mb-2">
              <p className="text-white">Coming Soon</p>
            </span>
          </div>
        </div>
      </div>
      <div>
        <div class="max-w-sm rounded overflow-hidden shadow-lg">
          <img class="w-full" src={cravings} alt="Sunset in the mountains" />
          <div class="px-6 py-4">
            <div class="font-medium text-lg font-sand mb-2 text-preggifyPurple">
              Sex in pregnancy
            </div>
            <p class="text-gray-700 text-sm font-normal font-sand">
              Best resource to understand sexual intimacy in pregnancy
            </p>
          </div>
          <div class="px-6 pt-2 pb-2">
            <span class="inline-block  bg-preggifyGreen font-sand rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
              <p className="text-white">Coming Soon</p>
            </span>
          </div>
        </div>
      </div>
      <div>
        <div class="max-w-sm rounded overflow-hidden shadow-lg">
          <img class="w-full" src={cravings} alt="Sunset in the mountains" />
          <div class="px-6 py-4">
            <div class="font-medium text-lg font-sand mb-2 text-preggifyPurple">
              Smoking and pregnancy
            </div>
            <p class="text-gray-700 text-sm font-normal font-sand">
              Understand how smoking affects pregnancy and your unborn baby.
            </p>
          </div>
          <div class="px-6 pt-2 pb-2">
            <span class="inline-block font-sand  bg-preggifyGreen rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
              <p className="text-white">Coming Soon</p>
            </span>
          </div>
        </div>
      </div>
      <div>
        <div class="max-w-sm rounded overflow-hidden shadow-lg">
          <img class="w-full" src={cravings} alt="Sunset in the mountains" />
          <div class="px-6 py-4">
            <div class="font-medium text-lg font-sand mb-2 text-preggifyPurple">
              Delivery preparations
            </div>
            <p class="text-gray-700 text-sm font-normal font-sand">
              Clear your confusion about delivery process and be incharge
            </p>
          </div>
          <div class="px-6 pt-2 pb-2">
            <span class="inline-block font-sand  bg-preggifyGreen rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
              <Link className="text-white">Coming Soon</Link>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnerAcademy;
