import logo from "./logo.svg";
import "./App.css";
import Home from "./components/Home";
import Signup from "./components/Signup";
import { Routes, Route } from "react-router-dom";
import Login from "./components/login";
import "flowbite-datepicker";
import UserLayout from "./components/Userdashboard/UserLayout";
import UserHomeMunu from "./components/Userdashboard/UserHomeMenu";
import Services from "./components/Userdashboard/Services";
import Videos from "./components/Userdashboard/LectureVideos";
import Excercise from "./components/Userdashboard/Excercise";
import Food from "./components/Userdashboard/Food";
import Medication from "./components/Userdashboard/Medication";
import Settings from "./components/Userdashboard/Settings";
import PlayScreen from "./components/Userdashboard/Academy/playScreen";
import FoodDets from "./components/Userdashboard/FoodDet";
import Mood from "./components/Userdashboard/MoodSettings";
import Soon from "./components/Userdashboard/Soon";
import Community from "./components/Userdashboard/community";
import ResultViewer from "./components/Userdashboard/resultViewer";
import Suggestions from "./components/Userdashboard/suggestions";
import WaitList from "./components/WaitList";
import Thankyou from "./components/thankyou";
import ResetPassword from "./components/ResetPass";
import SignupHerConomy from "./components/signupHerconomy";

function App() {
  return (
    <>
      {" "}
      {/* ceckin for dee.. */}{" "}
      <Routes>
        <Route path="/" element={<Home />} />{" "}
        <Route path="/signup" element={<Signup />} />{" "}
        <Route path="/herconomydiscountforme" element={<SignupHerConomy />} />{" "}
        <Route path="/login" element={<Login />} />{" "}
        <Route path="/waitlist" element={<WaitList />} />{" "}
        <Route path="/thankyou" element={<Thankyou />} />{" "}
        <Route path="/resetpass" element={<ResetPassword />} />{" "}
        {/* <Route path="/resetpassword" element={<ResetPassword />} />{" "} */}{" "}
        <Route path="/userHome" element={<UserLayout />}>
          <Route index path="/userHome/Dashboard" element={<UserHomeMunu />} />{" "}
          <Route path="/userHome/services" element={<Services />} />{" "}
          <Route path="/userHome/videos" element={<Videos />} />{" "}
          <Route path="/userHome/Excercise" element={<Excercise />} />{" "}
          <Route path="/userHome/nutrition" element={<Food />} />{" "}
          <Route path="/userHome/Medication" element={<Medication />} />{" "}
          <Route path="/userHome/Settings" element={<Settings />} />{" "}
          <Route path="/userHome/playScreen" element={<PlayScreen />} />{" "}
          <Route path="/userHome/FoodDetails" element={<FoodDets />} />{" "}
          <Route path="/userHome/MoodDetails" element={<Mood />} />{" "}
          <Route path="/userHome/ComingSoon" element={<Soon />} />{" "}
          <Route path="/userHome/Coming" element={<Soon />} />{" "}
          <Route path="/userHome/Communities" element={<Community />} />{" "}
          <Route path="/userHome/result" element={<ResultViewer />} />{" "}
          <Route path="/userHome/suggestions" element={<Suggestions />} />{" "}
        </Route>{" "}
      </Routes>{" "}
    </>
  );
}

export default App;
